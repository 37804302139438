import React, {useEffect, useState} from 'react'
import countryPickerData from '../../../constant/countryPickerData'
import styles from './countryPicker.module.scss'
import SmallArrow from '../../../icons/smallArrow.svg'
import PickList from './PickList/PickList'

const CountryPicker = ({ countryCode, handleChangeCountryCode }) => {
  const [pickListIsOpen, setPickListIsOpen] = useState(false)
  const countryObject = getCountryObject(countryCode)

  const handleOpenPickList = () => {
    setPickListIsOpen(!pickListIsOpen)
  }

  const handleClosePickList = () => {
    setPickListIsOpen(false)
  }

  const handleChangeCountryKey = (countryCode, callingCode) => {
    handleChangeCountryCode(countryCode, callingCode)
  }

  return (
      <div className={styles.countryPicker} onClick={handleOpenPickList}>
        <img src={countryObject.flag} alt={'flag'} />
        <img src={SmallArrow} alt={'arrow'} />
        {pickListIsOpen && (
            <PickList
                options={countryPickerData}
                onClose={handleClosePickList}
                onChange={handleChangeCountryKey}
            />
        )}
      </div>
  )
}

const getCountryObject = (countryCode) => {
  return countryPickerData.find((country) => country.key === countryCode)
}

export default CountryPicker
