import React from "react";
import styles from './settings.module.scss'
import PageComponent from '../PageComponent';
import Robbot from '../../../icons/robbot.svg';
import UserDetailsChange from './UserDetailsChange';
import PasswordChange from './PasswordChange';

const Settings = () => {

    return (
       <PageComponent>
           <div className={styles.settings}>
               <div>
                   <h1>Einstellungen</h1>
                   <UserDetailsChange />
                   <PasswordChange />
               </div>
               <div className={styles.robbot}>
                   <img src={Robbot} alt={Robbot} />
               </div>
           </div>
       </PageComponent>
    )
}

export default Settings
