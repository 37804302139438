import {quizQuestions, representationByCommerciallyActive} from "../../defaultStates";
import {
    SET_COMMERCIALLY_ACTIVE,
    SET_EMPTY_ACTIVE,
    SET_UPDATE_COMMERCIALLY_ACTIVE,
    SET_UPDATE_LEGAL_REPRESENTATION
} from "../../ActionTypes";

import store from "../../store";
// const {quizQuestions:{availableDays}} = store.getState()


export default (state = representationByCommerciallyActive , action = {}) => {
    switch (action.type) {
        case SET_COMMERCIALLY_ACTIVE:
        {
            const { key, value, index, subKey ,count} = action.payload;

            if (key === 'activeDates') {
                const updatedActiveDates = [...state.activeDates];

                if (typeof index === 'number') {

                    if (subKey === 'durationOfAbsence') {
                        // Update a subkey inside availableDates for a specific item
                        updatedActiveDates[index] = {
                            ...updatedActiveDates[index],
                            durationOfAbsence: value
                        };
                    } else if(subKey === 'availableDates'){
                        updatedActiveDates[index] = {
                            durationOfAbsence:updatedActiveDates[index]?.durationOfAbsence,
                            availableDates: {
                                ...updatedActiveDates[index]?.availableDates,
                                ...value,


                            }
                        }

                    }
                }else {
                    // Update a key directly for a specific item
                    updatedActiveDates[index] = {
                        ...updatedActiveDates[index],
                        [key]: value,
                    };
                }
                // else {
                //     // Add a new default object to the array
                //     updatedIndividualDates.push({
                //         durationOfAbsence: '',
                //         availableDates: {
                //             date: '',
                //             duration: '',
                //             amount: ''
                //         }
                //     });
                // }
                (updatedActiveDates[index]?.availableDates?.amount || updatedActiveDates[index]?.availableDates?.amount === '0') && count && updatedActiveDates.length !== count && updatedActiveDates.push({
                    durationOfAbsence: '',
                    availableDates: {
                        date: '',
                        duration: '',
                        amount: ''
                    }
                });

                return {
                    ...state,
                    activeDates: updatedActiveDates,
                };
            }
            return {
                ...state,
                [key]: value,
            };
        }

        case SET_UPDATE_COMMERCIALLY_ACTIVE:
            return {
                ...action.representationByCommerciallyActive
            }

        case SET_EMPTY_ACTIVE:
            return {
                ...action.active
            }
        default:
            return state
    }
}
