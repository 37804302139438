import React, {useState, useEffect} from "react";
import styles from '../resetPasswordSteps.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import {
    emptySignInWithEmailFormValidation,
    setSignInWithEmailFormValidation
} from '../../../../../../redux/actions/validation';
import ArrowRight from '../../../../../../icons/arrowRight.svg'
import PageComponent from '../../../../PageComponent';
import Robbot from '../../../../../../icons/robbot.svg'
import { setSignUpSignInResetStep, setVerificationCode } from '../../../../../../redux/actions/authActions';
import ContactPerson from '../../../../Common/ContactPerson';
import isNumber from '../../../../../../utils/validators/isNumber'
import { verifyForgotPassword } from '../../../../../../api/loginApi';
import ErrorIcon from '../../../../../../icons/errorIcon.svg'
import Button from '../../../../../UiKitComponents/Button';
import cx from 'classnames';

const EnterCode = ({setToken, email, submitUserEmail, errorMessage: error}) => {
    const dispatch = useDispatch()
    const {
        validation: {
            signInWithEmailFormValidation: { verificationCodeErr }
        },
        auth: { verificationCode, resetpassword: { step } }
    } = useSelector((state) => state)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifyDisabled, setVerifyDisabled] = useState(false)

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [verificationCode, verificationCodeErr])

    useEffect(() => {
        return () => {
            dispatch(emptySignInWithEmailFormValidation())
        }
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleVerify()
        }
    }

    const validationCode = (field, value) => {
        let inValid = false
        if (!value.trim()) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Err', 'Code is required'))
        } else if (value.trim().length !== 6) {
            inValid = true
            dispatch(setSignInWithEmailFormValidation(field + 'Err', 'Code is invalid'))
        }
        return inValid
    }
    const handleChangeCode = (field, value, e) =>{
        if((isNumber(value) || e.currentTarget.value === '') && value.length <= 6){
            dispatch(setVerificationCode(value))
            dispatch(setSignInWithEmailFormValidation(field + 'Err', null))
            if (value.length === 6) {
                e.currentTarget.blur()
            }
        }
    }
    const handleBlurCodeField = (field, value, e) => {
        if(e?.relatedTarget?.type!="submit"){
            setErrorMessage('')
            validationCode(field, value)
        }

    }
    const handleVerify = () => {
        if (validationCode('verificationCode', verificationCode)){
            return
        }
        setVerifyDisabled(true)
        verifyForgotPassword({ email, code: verificationCode })
            .then((data) => {
                dispatch(setSignUpSignInResetStep('resetpassword', { step: 2 }))
                setToken(data.token)
            })
            .catch((err) => {
                if(err.errorCode === 'REIMBURSEMENT_ACCOUNT_FORGOT_PASSWORD_INVALID_CODE'){
                    setErrorMessage('Der Code ist falsch.')
                }
            })
            .finally(() => setVerifyDisabled(false))
    }
    const handleResendEmail = () => {
        submitUserEmail()
    }
    const message = errorMessage || verificationCodeErr

    return (
        <PageComponent>
            <div className={styles.forgotPassword}>
                <div>
                    <div>
                        <div className={styles.challenge}>
                            <p>Wir haben Ihnen einen Code gesendet</p>
                            <p>OWir haben Ihnen einen sechsstelligen Code an Ihre registrierte E-Mail-Adresse geschickt.
                                Bitte geben Sie den Code unten ein, um Ihre Identität zu bestätigen und Ihr Passwort zurückzusetzen.</p>
                        </div>
                        <div className={cx(styles.form,{[styles.enterCodeForm]:step === 1})}>
                            <div>
                                <input
                                    type='text'
                                    placeholder={'------'}
                                    value={verificationCode}
                                    onChange={(e)=> handleChangeCode('verificationCode', e.currentTarget.value, e)}
                                    onBlur={(e) => handleBlurCodeField('verificationCode', e.currentTarget.value, e)}
                                    maxLength="250"
                                    className={cx(styles.confirmCodeField, {
                                        [styles.invalidInput]: message,
                                    })}
                                />
                                {message && (
                                    <div className={styles.errorMessage}>
                                        <img src={ErrorIcon} alt={ErrorIcon} />
                                        <span>{message}</span>
                                    </div>
                                )}
                            </div>
                            <button
                                onClick={handleVerify}
                                verifyDisabled={verifyDisabled}
                                className={cx({
                                    [styles.disabled]: verifyDisabled})}
                            >
                                Bestätigen <img src={ArrowRight} alt={ArrowRight}/>
                            </button>
                            <Button
                                linkBtn
                                onClick={handleResendEmail}
                                // disabled={disabled}
                            >
                                erneut senden
                            </Button>
                            {error && (
                                <div className={styles.errorMessage}>
                                    <img src={ErrorIcon} alt={ErrorIcon} />
                                    <span>{error}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.robbot}>
                        <img src={Robbot} alt={Robbot}/>
                    </div>
                </div>
                <div>
                    <ContactPerson />
                </div>
            </div>
        </PageComponent>
    )
}

export default EnterCode












