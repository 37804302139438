import {
    SET_NOT_ACTIVE_BTN
} from "../../ActionTypes";
import {nonActiveBtn} from "../../defaultStates";

export default (state = nonActiveBtn , action = {}) => {
    switch (action.type) {
        case SET_NOT_ACTIVE_BTN:
            return {
                activeBtn : action.activeBtn
            }
        default:
            return state
    }
}



