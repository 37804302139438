import React, { useState } from 'react'
import EnterCode from './EnterCode'
import SetNewPassword from './SetNewPassword/SetNewPassword'
import { useDispatch, useSelector } from 'react-redux'
import EnterEmail from './EnterEmail';
import { setSignUpSignInResetStep } from '../../../../../redux/actions/authActions';
import { submitEmail } from '../../../../../api/loginApi';

const ResetPasswordSteps = () => {
    const dispatch = useDispatch()
    const {
        auth: {
            resetpassword : { step }
        },
        userDetails : {
            email : emailInitialState
        }
    } = useSelector(
        (state) => state
    )
    const [email, setEmail] = useState(emailInitialState)
    const [errorMessage, setErrorMessage] = useState('')
    const [token, setToken] = useState('')
    const [disabled, setDisabled] = useState(false)

    const submitUserEmail = () => {
        setDisabled(true)
        submitEmail({email})
            .then(() => {
                setErrorMessage('')
                dispatch(setSignUpSignInResetStep('resetpassword', { step: 1 }))

            })
            .catch((err) => {
                console.log(err)
                setErrorMessage(err)
            })
            .finally(() => setDisabled(false))
    }

    return (
        <>
            {step === 0 ? (
                <EnterEmail
                    submitUserEmail={submitUserEmail}
                    email={email}
                    setEmail={setEmail}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    disabled={disabled}
                />
            ) : (
                []
            )}
            {step === 1 ? (
                <EnterCode
                    submitUserEmail={submitUserEmail}
                    email={email}
                    setToken={setToken}
                    errorMessage={errorMessage}
                    // disabled={disabled}
                />
            ) : (
                []
            )}
            {step === 2 ?
                <SetNewPassword
                    token={token}
                    email={email}
                /> : []}
        </>
    )
}

export default ResetPasswordSteps