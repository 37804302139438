import React, {useEffect, useRef, useState} from "react";
import styles from '../../../Pages/Common/Footer/footer.module.scss'
import {useDispatch, useSelector} from "react-redux";
import Amount from '../../../../icons/amount.svg'
import {setCommercially, setIndividual, setNotActiveBtn} from "../../../../redux/actions/careReceipients";
import { setCommerciallyActive} from "../../../../redux/actions/quizQuestions";
import CalendarIcon from "../../../../icons/calendar.svg";
import Calendar from "react-calendar";
import moment from "moment";
import RightArrow from "../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../icons/leftArrowIcon.svg";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import Time from "../../../../icons/time.svg";
import cx from "classnames";
import Logo from "../../../../icons/miniLogo.svg";
import {activeBtn} from "../../../../redux/defaultStates";
import {calculateAge} from "../../../../constant/constants";
const QuizCommerciallyActive = ({parentState,setParentState,currentIndexActive}) => {
    const { commerciallyActive:{quizActive,indexActive} , availableDays} = useSelector((state) => state.quizQuestions)
    const { representationByCommerciallyActive,authorizedPerson:{ periodOfAbsenceFrom, periodOfAbsenceTo}, nonActiveBtn: {activeBtn}} = useSelector((state) => state)
    const dispatch = useDispatch()
    // const { selectedLanguage } = useSelector((state) => state.metadata)
    // const [answerActive, setAnswerActive] = useState('')
    const [duration, setDuration] = useState( '')
    const [amount, setAmount] = useState('')
    const [showCalendar, setShowCalendar] = useState(false)
    const wrapper = useRef(null);
    const [question, setQuestion] = useState('')
    const [dates, setDates] = useState([]);
    // const [currentIndex, setCurrentIndex] = useState(currentIndexActive);
    const [isComplete, setIsComplete] = useState(false);
    let notObjIndex = indexActive  - representationByCommerciallyActive.activeDates.length
    // useEffect(() => {
    //     console.log(notObjIndex);
    //     Object.values(representationByCommerciallyActive)[0][notObjIndex + 1]?.availableDates.date && setCurrentIndex(currentIndex + 1)
    // }, [Object.values(representationByCommerciallyActive)[0][notObjIndex + 1]?.availableDates.date]);
    useEffect(() => {
        document.addEventListener("click", handleClickOut, false);
        return () => {
            document.removeEventListener("click", handleClickOut, false);
        };
    }, []);
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    // Function to generate the date range iteratively to avoid misordering
    const generateDateRange = (start, end) => {
        const startDate = new Date(start.split(".").reverse().join("-")); // Convert 'DD.MM.YYYY' to 'YYYY-MM-DD'
        const endDate = new Date(end.split(".").reverse().join("-"));
        const dateArray = [];

        let currentDate = startDate;
        while (currentDate <= endDate) {
            dateArray.push(formatDate(currentDate)); // Add formatted date
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return dateArray;
    };

    // Generate the date interval when the component mounts
    useEffect(() => {
        const startDate = convertToDate(periodOfAbsenceFrom);
        const endDate = convertToDate(periodOfAbsenceTo);
        const generatedDates = generateDateRange(startDate, endDate);
        setDates(generatedDates); // Set the generated dates in state
    }, [periodOfAbsenceFrom,periodOfAbsenceTo]);

    const convertMinutesToHours = (duration) => {
        const hours = Math.ceil(duration / 60)
        return hours;
    }
    const handleClickOut = event => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setShowCalendar(false)
        }
    };
    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return date.toString();
    }
    const handleChangeAnswer = (e,itemQuestion) => {
            setParentState(e.currentTarget.value)
        setQuestion(itemQuestion)
    }
    const handleChangeDuration = (e) => {
        setDuration(e.currentTarget.value)
    }
    const handleChangeAmount = (e) => {
        setAmount(e.currentTarget.value)
    }

    const handleActiveAnswer = () => {
        console.log(dates);
        const newQuiz= quizActive.map((item , i)=> {
                if(i === indexActive){
                    return {
                        ...item,
                        answers: duration ? {
                            date: dates[currentIndexActive],
                            duration: `${duration} Std.`,
                            amount:`€ ${amount}`
                        } : parentState,
                    }
                }
                return item
            })

        if(availableDays && Object.values(representationByCommerciallyActive)[0].length !== availableDays &&
            (Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence ||
                Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence === '-')){
            newQuiz.splice(indexActive + 1, 0, {
                    question: " War sie aus diesem Grund ganztags verhindert ?",
                    // Select box (Less than 8 hours (default), More than 8 hours)
                    answers: '',
                    img: Logo,
                    type: 'submit',
                    required: false,
                    questionKey: 'durationOfAbsence'
                },
                {
                    answers: {
                        date:'',
                        duration:'',
                        amount:''
                    },
                    img: Logo,
                    type: 'group',
                    required: false,
                    questionKey: 'availableDates'
                });
        }
        !Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence || duration ?
            dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[0],amount ? {
                date: dates[currentIndexActive],
                duration: `${duration} Std.`,
                amount:`€ ${amount}`
            } : parentState,Object.values(representationByCommerciallyActive)[0].length-1,amount ? 'availableDates': 'durationOfAbsence', availableDays) ) :
            dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[representationByCommerciallyActive.activeDates.length === 1 ? indexActive : notObjIndex - 2],parentState,null,null))
        // amount  ? dispatch(setCommercially(representationByCommerciallyActive.length -1, 'availableDates' , {
        //         date: dates[currentIndex],
        //         duration: convertMinutesToHours(duration),
        //         amount:amount
        //     }, availableDays)) : dispatch(setCommercially(representationByCommerciallyActive.length -1 , 'durationOfAbsence', parentState === 'Ja'? 'über 8 Stunden täglich' : 'unter 8 Stunden täglich'))

            dispatch(setCommerciallyActive(newQuiz,indexActive + 1))
        // if (currentIndex < dates.length - 1) {
        //     amount && setCurrentIndex(currentIndex + 1); // Increment index
        // } else {
        //     setIsComplete(true); // Mark as complete if all dates are shown
        // }
        setParentState('')
        setAmount('')
        setDuration('')
    }
    const  handleChangeActiveCalendar = (e) =>{
        setParentState(moment(e).format('DD.MM.YYYY'))
        setShowCalendar(false)
    }

    // console.log(convertToDate(answerActive));
    const handleShowActiveCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }
    return (
        <>
            {quizActive?.map((item,i)=>{
                if(i === indexActive) {
                    switch (item?.type) {
                        case 'group':
                            return (<div className={styles.group}>
                                    <div>
                                        <input placeholder="Datum" value={dates[currentIndexActive]} readOnly={true} />
                                        <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowActiveCalendar}/>
                                        {/*{!!showCalendar && <Calendar*/}
                                        {/*    onChange={(e) => handleChangeActiveCalendar(e)}*/}
                                        {/*    // value={!answerActive && periodOfAbsenceFrom ? new Date(convertToDate(periodOfAbsenceFrom)) : !answerActive && periodOfAbsenceFrom === '-' ?*/}
                                        {/*    //     null*/}
                                        {/*    //     : convertToDate(answerActive)}*/}
                                        {/*    minDate={periodOfAbsenceFrom ? new Date(convertToDate(periodOfAbsenceFrom)) : null}*/}
                                        {/*    maxDate={periodOfAbsenceTo ? new Date(convertToDate(periodOfAbsenceTo)) : null}*/}
                                        {/*    className={styles.reactCalendar}*/}
                                        {/*    nextLabel={<img src={RightArrow} alt={'next'}/>}*/}
                                        {/*    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}*/}
                                        {/*    next2Label={null}*/}
                                        {/*    prev2Label={null}*/}
                                        {/*    locale={'de'}*/}
                                        {/*/>}*/}
                                    </div>
                                    <div>
                                        <input required placeholder={'Dauer/Std.'} value={duration} type={'number'} pattern=""
                                               onInput={(e) => e.currentTarget.value = e.currentTarget.value.slice(0, 2)}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') handleActiveAnswer()}} key={i}
                                               onChange={(e)=>handleChangeDuration(e)} autoFocus={true}/>
                                        <img src={Time} alt={Time} />
                                    </div>

                                    <div>
                                        <input type={'number'} pattern="" placeholder={'Betrag'} value={amount}
                                               onChange={(e)=>handleChangeAmount(e)}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') handleChangeAmount(e)}} key={i} autoFocus={true}/>
                                        <img src={Amount} alt={Amount} />
                                    </div>
                                </div>

                            )
                        case 'submit' :
                            return (<span>
                                    <input type={'submit'} value={'Ja'}
                                           onClick={(e)=>handleChangeAnswer(e,item.question)} key={i} className={styles.care} />
                                   <input type={'submit'} value={'Nein'}
                                          onClick={(e)=>handleChangeAnswer(e,item.question)} key={i} className={styles.care} autoFocus={true}/>
                                    </span>)
                    }
                }
            })
        }

            { (2 * Number(availableDays) !== indexActive && !activeBtn) &&
                <button disabled={(!parentState && !duration && !amount)}
                        className={cx({ [styles.btnDisabled]: (!parentState && !duration && !amount) })} onClick={handleActiveAnswer}>
                <img src={ArrowBtn} alt={ArrowBtn} />
            </button>}

        </>
    )
}
export default QuizCommerciallyActive
