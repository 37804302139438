import {representationByPrivateIndividual} from "../../defaultStates";
import {
    SET_EMPTY_PRIVATE,
    SET_PRIVATE_INDIVIDUAL, SET_UPDATE_COMMERCIALLY_ACTIVE, SET_UPDATE_PRIVATE_INDIVIDUAL
} from "../../ActionTypes";

export default (state = representationByPrivateIndividual , action = {}) => {
    switch (action.type) {
        case SET_PRIVATE_INDIVIDUAL:
        {
            const { key, value, index, subKey ,count} = action.payload;

            if (key === 'individualDates') {
                const updatedIndividualDates = [...state.individualDates];

                if (typeof index === 'number') {

                    if (subKey === 'durationOfAbsence') {
                        // Update a subkey inside availableDates for a specific item
                        updatedIndividualDates[index] = {
                            ...updatedIndividualDates[index],
                            durationOfAbsence: value
                        };
                    } else if(subKey === 'availableDates'){
                        updatedIndividualDates[index] = {
                            durationOfAbsence:updatedIndividualDates[index]?.durationOfAbsence,
                            availableDates: {
                                ...updatedIndividualDates[index]?.availableDates,
                               ...value,


                            }
                        }

                    }
                }else {
                    // Update a key directly for a specific item
                    updatedIndividualDates[index] = {
                        ...updatedIndividualDates[index],
                        [key]: value,
                    };
                }
                // else {
                //     // Add a new default object to the array
                //     updatedIndividualDates.push({
                //         durationOfAbsence: '',
                //         availableDates: {
                //             date: '',
                //             duration: '',
                //             amount: ''
                //         }
                //     });
                // }
                (updatedIndividualDates[index]?.availableDates?.amount || updatedIndividualDates[index]?.availableDates?.amount === '0') && count && updatedIndividualDates.length !== count && updatedIndividualDates.push({
                    durationOfAbsence: '',
                    availableDates: {
                        date: '',
                        duration: '',
                        amount: ''
                    }
                });

                return {
                    ...state,
                    individualDates: updatedIndividualDates,
                };
            }

            // Update other keys in the state
            return {
                ...state,
                [key]: value,
            };
        }
        case SET_UPDATE_PRIVATE_INDIVIDUAL:
            return {
                ...action.representationByPrivateIndividual
            }
        case SET_EMPTY_PRIVATE:
            return {
                ...action.privat
            }
        default:
            return state
    }
}
