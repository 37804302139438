import React, {useEffect, useState} from "react";
import styles from '../ChallengeSteps/challengeSteps.module.scss'
import Arrow from "../../../../icons/arrow.svg";
import cx from "classnames";
import Download from "../../../../icons/download.svg";
import {useDispatch, useSelector} from "react-redux";
import Edit from '../../../../icons/edit.svg'
import {useNavigate} from "react-router-dom";
import Checked from '../../../../icons/checkbox-checked.svg'
import Checke from '../../../../icons/checkbox-checked.svg'
import {
    setAuthPerson,
    setCareQuizQuestions, setCommerciallyActive,
    setEditFromSummary, setEditParams, setFormEdit,
    setLegalRepresentation, setPrivateIndividual, setRepresentativePerson
} from '../../../../redux/actions/quizQuestions';
import {editQuiz} from "../../../../constant/constants";
const Details = ({notCareAnsweredIndex}) => {
    const {
        careReceipients,
        legalRepresentations ,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual,
        quizQuestions
    } = useSelector((state) => state)
    const dispatch = useDispatch()
    const { quizCareReceipients:{ quizCare,indexCare},
        quizLegalRepresentation:{quizLegal,indexLegal},
        quizAuthorizedPerson:{quizAuth,indexAuth},
        quizRepresentativePerson:{quizRep,indexRep},
        commerciallyActive:{quizActive,indexActive},
        privateIndividual:{quizPrivate,indexPrivate},
    } = useSelector((state) => state.quizQuestions)
    const [rotateCare, setRotateCare] = useState(false)
    const [rotateLegal, setRotateLegal] = useState(false)
    const [rotateAuth, setRotateAuth] = useState(false)
    const [rotateRep, setRotateRep] = useState(false)
    const [rotateActive, setRotateActive] = useState(false)
    const [rotatePrivate, setRotatePrivate] = useState(false)
    const { pathname } = window.location
    const [edit, setEdit] = useState(false)
    const [groupName  , setGroupName] = useState('')
    const [question, setQuestion] = useState('')
    let navigate = useNavigate();
    const fileNameCare = careReceipients.certificate === '-' ? '-' : quizCare.find((item)=>item.type === 'file')?.fileName
    const fileNameLegal = legalRepresentations.document === '-' ? '-' : quizLegal.find((item)=>item.type === 'file')?.fileName
    const [windowWidth, setWindowWidth] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const handleResize = () => {
        setWindowWidth({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleRotateCare = () => {
        if(!rotateCare) {
            setRotateCare(true)
        } else {
            setRotateCare(false)
        }
    }
    const handleRotateRep = () => {
        if(!rotateRep) {
            setRotateRep(true)
        } else {
            setRotateRep(false)
        }
    }
    const handleRotateActive = () => {
        if(!rotateActive) {
            setRotateActive(true)
        } else {
            setRotateActive(false)
        }
    }
    const handleRotatePrivate = () => {
        if(!rotatePrivate) {
            setRotatePrivate(true)
        } else {
            setRotatePrivate(false)
        }
    }
    const handleRotateLegal = () => {
        if(!rotateLegal){
            setRotateLegal(true)
        }else{
            setRotateLegal(false)
        }
    }
    const handleRotateAuth = () =>{
        if(!rotateAuth){
            setRotateAuth(true)
        }else{
            setRotateAuth(false)
        }
    }
    // const handleEdit = (i) => {
    //     setEditList(i)
    // }
    const backToChallenge = (e, groupName, question ) =>{
        navigate('/startChallenge')
        for (const key in quizQuestions[editQuiz[groupName]]){
            const subObj = quizQuestions[editQuiz[groupName]][key]
            if(Array.isArray(subObj)){
                subObj.find((item)=>item.questionKey === question)
                const newQuizType = subObj.map((item, index) => {
                    if(item.questionKey === question){
                        dispatch(setEditParams({
                            index,
                            groupName
                        }))
                        return {
                            ...item,
                            edit: true,
                            // fileName: question
                        }
                    }
                    return item
                })
                const quizKey = quizQuestions[editQuiz[groupName]][key];
                switch(quizKey){
                    case quizCare:
                        dispatch(setCareQuizQuestions(newQuizType, indexCare));
                        break;
                    case quizLegal:
                        dispatch(setLegalRepresentation(newQuizType, indexLegal));
                        break;
                    case quizAuth:
                        dispatch(setAuthPerson(newQuizType, indexAuth));
                        break;
                    case quizRep:
                        dispatch(setRepresentativePerson(newQuizType, indexRep));
                        break;
                    case quizActive:
                        dispatch(setCommerciallyActive(newQuizType, indexActive));
                        break;
                    default:
                        dispatch(setPrivateIndividual(newQuizType, indexPrivate));
                }
            }

        }

        const childElement = e.target.closest('p');
        dispatch(setEditFromSummary(childElement.innerText, question))
        dispatch(setFormEdit(true))
    }
    const renderListItem = (group, label, value, onClick) => {
        // console.log(Array.isArray(value));
        return (
            value ? (
                <li>
                    {typeof value === 'object' ?
                        <p>
                            {Object.values(value).map((item) => (
                                <>
                                    {item.durationOfAbsence && <p>Dauer der Verhinderung der normalerweise pflegenden Person unter oder über 8
                                        Stunden täglich</p>}
                                    {item.availableDates?.amount && <p>Recipients Dates</p>}
                                </>
                            ))}
                        </p>
                        :  <p>{label}</p>
                    }

                    <p onMouseOver={() => {
                        setGroupName(group)
                        setQuestion(label)
                        setEdit(true)
                    }} onMouseOut={() => {
                        setGroupName('')
                        setQuestion('')
                        setEdit(false)
                    }}>
                        {typeof value === 'object' ?
                            <p>
                                {Object.values(value).map((item, index) => (
                                    <p>
                                        {item.durationOfAbsence && <p>{item.durationOfAbsence}</p>}
                                        {item.availableDates?.amount  && <p>{item.availableDates?.date}, {item.availableDates?.duration},
                                            {item.availableDates?.amount}
                                        </p>}
                                    </p>
                                ))}
                            </p>
                            : <p>{value}</p>
                        }
                        {pathname === '/summary' && edit && question === label && group === groupName && onClick && !Array.isArray(value) && (
                            <img
                                src={Edit}
                                alt="Edit"
                                style={{marginLeft: '10px'}}
                                onClick={(e) => onClick(e)}
                            />
                        )}
                    </p>
                </li>
            ) : null
        )
    };
    const downloadFile = (url, name) => {
        if(url === '-'){
            return null
        }else{
            fetch(url)
                .then((response) => (response.blob()))
                .then((blob) => {
                    const url = window.URL.createObjectURL(
                        new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `${name}`,
                    );
                    document.body.appendChild(link);
                    link.click();
                    link?.parentNode?.removeChild(link);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    return(
        <div className={cx(styles.details)}>
            {notCareAnsweredIndex !== 0  && <div>
                <div>
                    <div>
                        <p>Pflegebedürftige Person</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotateCare} className={cx({
                            [styles.rotate]: rotateCare
                        })}/>
                    </div>

                    {!!rotateCare && <div className={cx(styles.careReceipients,{
                        [styles.certificate] : careReceipients.certificate !== ''
                    })}>
                        <ul>
                            {renderListItem('careReceipients','Name des Pflegebedürftigen', careReceipients.name, (e) => backToChallenge(e, 'careReceipients', 'name'))}
                            {renderListItem('careReceipients','Vorname des Pflegbedürftigen', careReceipients.lastName, (e) => backToChallenge(e, 'careReceipients', 'lastName'))}
                            {renderListItem('careReceipients','Geburtsdatum des Pflegebedürftigen',careReceipients.birthday === '-' ? '-' : careReceipients.birthday, (e) => backToChallenge(e, 'careReceipients', 'birthday'))}
                            {renderListItem('careReceipients','Anschrift Strasse des Pflegebedürftigen', careReceipients.address, (e) => backToChallenge(e, 'careReceipients', 'address'))}
                            {renderListItem('careReceipients','Hausnummer des Pflegebedürftigen', careReceipients.houseNumber, (e) => backToChallenge(e, 'careReceipients', 'houseNumber'))}
                            {renderListItem('careReceipients','PLZ des Pflegebedürftigen', careReceipients.zipCode, (e) => backToChallenge(e, 'careReceipients', 'zipCode'))}
                            {renderListItem('careReceipients','Ort des Pflegebedürftigen', careReceipients.city, (e) => backToChallenge(e, 'careReceipients', 'city'))}
                            {renderListItem('careReceipients','Telefonnummer des Pflegebedürftigen (optional)', careReceipients.phoneNumber, (e) => backToChallenge(e, 'careReceipients', 'phoneNumber'))}
                            {renderListItem('careReceipients','Krankenversicherung des Pflegebedürftigen', careReceipients.insurance, (e) => backToChallenge(e, 'careReceipients', 'insurance'))}
                            {renderListItem('careReceipients','Versicherungsnummer des Pflegebedürftigen', careReceipients.insuranceNumber.replaceAll('&amp;amp;', "&"), (e) => backToChallenge(e, 'careReceipients', 'insuranceNumber'))}
                            {renderListItem('careReceipients','Pflegegrad des Pflegebedürftigen', careReceipients.levelOfCare)}
                            {careReceipients.certificate && (
                                <li className={styles.file}>
                                    <p>Kopie des Pflegebescheids</p>
                                    <p onMouseOver={() => {
                                        setGroupName('careReceipients')
                                        setQuestion('Kopie des Pflegebescheids')
                                        setEdit(true)
                                    }} onMouseOut={() => {
                                        setGroupName('')
                                        setQuestion('')
                                        setEdit(false)
                                    }}>
                                        <p>
                                            {fileNameCare}
                                        </p>
                                        <img src={Download} alt="Download"
                                             onClick={() => downloadFile(careReceipients.certificate, fileNameCare)}/>
                                        {/*{pathname === '/summary' && edit && question === 'Kopie des Pflegebescheids' && 'careReceipients' === groupName &&*/}
                                        {/*    <img*/}
                                        {/*        src={Edit}*/}
                                        {/*        alt="Edit"*/}
                                        {/*        style={{marginLeft: '10px'}}*/}
                                        {/*        onClick={(e) => backToChallenge(e, 'careReceipients', 'certificate')}*/}
                                        {/*    />}*/}
                                    </p>
                                </li>
                            )}
                            {renderListItem('careReceipients', 'Betreuung angeordnet', careReceipients.legalRepresentation)}
                        </ul>
                    </div>
                    }
                </div>
            </div> }
            {(legalRepresentations.name && careReceipients.legalRepresentation !=='Nein') && <div>
                <div>
                    <div>
                        <p>Betreuer der pflegebedürftigen Person</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotateLegal} className={cx({
                            [styles.rotate]: rotateLegal
                        })}/>
                    </div>
                    {!!rotateLegal && <div className={cx(styles.careReceipients,{
                        [styles.certificate] : careReceipients.certificate !== ''
                    })}>
                        <ul>
                            {renderListItem('legalRepresentations','Name des Betreuers des Pflegebedürftigen', legalRepresentations.name, (e) => backToChallenge(e, 'legalRepresentations', 'insuranceNumber'))}
                            {renderListItem('legalRepresentations','Vorname des Betreuers des Pflegebedürftigen', legalRepresentations.lastName, (e) => backToChallenge(e, 'legalRepresentations', 'lastName'))}
                            {renderListItem('legalRepresentations','Anschrift Strasse des Betreuers des Pflegebedürftigen', legalRepresentations.address,(e) => backToChallenge(e, 'legalRepresentations', 'address'))}
                            {renderListItem('legalRepresentations','Hausnummer des Betreuers des Pflegebedürftigen', legalRepresentations.houseNumber,(e) => backToChallenge(e, 'legalRepresentations', 'houseNumber'))}
                            {renderListItem('legalRepresentations','PLZ des Betreuers des Pflegebedürftigen', legalRepresentations.zipCode,(e) => backToChallenge(e, 'legalRepresentations', 'zipCode'))}
                            {renderListItem('legalRepresentations','Ort des Betreuers des Pflegebedürftigen', legalRepresentations.city,(e) => backToChallenge(e, 'legalRepresentations', 'city'))}
                            {renderListItem('legalRepresentations','Telefonnummer des Betreuers des Pflegebedürftigen', legalRepresentations.phoneNumber,(e) => backToChallenge(e, 'legalRepresentations', 'phoneNumber'))}
                            {/*{renderListItem('legalRepresentations','Legal Document', legalRepresentations.document,(e) => backToChallenge(e, 'legalRepresentations', 'document'))}*/}
                            {legalRepresentations.document && (
                                <li className={styles.file}>
                                    <p>Unterlagen</p>
                                    <p onMouseOver={() => {
                                        setGroupName('legalRepresentations')
                                        setQuestion('Kopie des Betreuungsbescheids')
                                        setEdit(true)
                                    }} onMouseOut={() => {
                                        setGroupName('')
                                        setQuestion('')
                                        setEdit(false)
                                    }}>
                                        <p>
                                            {fileNameLegal}
                                        </p>
                                        <img src={Download} alt="Download" onClick={()=>downloadFile(legalRepresentations.document,fileNameLegal)}/>
                                        {/*{pathname === '/summary' && edit && question === 'Kopie des Betreuungsbescheids' && 'legalRepresentations' === groupName  && <img*/}
                                        {/*    src={Edit}*/}
                                        {/*    alt="Edit"*/}
                                        {/*    style={{marginLeft: '10px'}}*/}
                                        {/*    // onClick={(e) => backToChallenge(e, 'legalRepresentations', 'document')}*/}
                                        {/*/>}*/}
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                    }
                </div>
            </div>}
            {authorizedPerson.name && <div>
                <div>
                    <div>
                        <p>übliche, eingetragene Pflegeperson</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotateAuth} className={cx({
                            [styles.rotate]: rotateAuth
                        })}/>
                    </div>
                    {!!rotateAuth && <div className={cx(styles.careReceipients)}>
                        <ul>
                            {renderListItem('authorizedPerson','Name der üblicherweise pflegenden Person', authorizedPerson.name, (e) => backToChallenge(e, 'authorizedPerson', 'name'))}
                            {renderListItem('authorizedPerson','Vorname der üblicherweise pflegenden Person', authorizedPerson.lastName, (e) => backToChallenge(e, 'authorizedPerson', 'lastName'))}
                            {renderListItem('authorizedPerson','Geburtsdatum der üblicherweise pflegenden Person',authorizedPerson.birthday === '-' ? '-' : authorizedPerson.birthday,(e) => backToChallenge(e, 'authorizedPerson', 'birthday'))}
                            {renderListItem('authorizedPerson','Anschrift der üblicherweise pflegenden Person', authorizedPerson.address,(e) => backToChallenge(e, 'authorizedPerson', 'address'))}
                            {renderListItem('authorizedPerson','Hausnummer der üblicherweise pflegenden Person', authorizedPerson.houseNumber,(e) => backToChallenge(e, 'authorizedPerson', 'houseNumber'))}
                            {renderListItem('authorizedPerson','PLZ üblicherweise pflegenden Person', authorizedPerson.zipCode,(e) => backToChallenge(e, 'authorizedPerson', 'zipCode'))}
                            {renderListItem('authorizedPerson','Ort der üblicherweise pflegenden Person', authorizedPerson.city,(e) => backToChallenge(e, 'authorizedPerson', 'city'))}
                            {renderListItem('authorizedPerson','Telefonnummer der üblicherweise pflegenden Person(optional)', authorizedPerson.phoneNumber,(e) => backToChallenge(e, 'authorizedPerson', 'phoneNumber'))}
                            {renderListItem('authorizedPerson','Normalerweise pflegende Person ist eine Pflegefirma oder sonst gewerblich pflegend', authorizedPerson.professionalCarer)}
                            {renderListItem('authorizedPerson','Zeitraum der Pflege der normalerweise pflegenden Person', authorizedPerson.periodOfCare)}
                            {renderListItem('authorizedPerson','Verhinderungsszeitraum der normalerweise pflegenden Person von',authorizedPerson.periodOfAbsenceFrom === '-' ? '-' : authorizedPerson.periodOfAbsenceFrom,(e) => backToChallenge(e, 'authorizedPerson', 'periodOfAbsenceFrom'))}
                            {renderListItem('authorizedPerson','Verhinderungszeitraum der normalerweise pflegenden Person bis',authorizedPerson.periodOfAbsenceTo === '-' ? '-' : authorizedPerson.periodOfAbsenceTo,(e) => backToChallenge(e, 'authorizedPerson', 'periodOfAbsenceTo'))}
                            {renderListItem('authorizedPerson','Grund der Verhinderung der normalerweise pflegenden Person', authorizedPerson.reasonForTheAbsence,(e) => backToChallenge(e, 'authorizedPerson', 'reasonForTheAbsence'))}
                            {renderListItem('authorizedPerson','Dauer der Verhinderung der normalerweise pflegenden Person unter oder über 8 Stunden täglich', authorizedPerson.durationOfAbsence,(e) => backToChallenge(e, 'authorizedPerson', 'durationOfAbsence'))}
                        </ul>
                    </div>
                    }
                </div>
            </div>}
            {representativePerson.name && <div>
                <div>
                    <div>
                        <p>vertretende Pflegeperson</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotateRep} className={cx({
                            [styles.rotate]: rotateRep
                        })}/>
                    </div>
                    {!!rotateRep && <div className={cx(styles.careReceipients)}>
                        <ul>
                            {renderListItem('representativePerson','Name der vertretenden Person', representativePerson.name,(e) => backToChallenge(e, 'representativePerson', 'name'))}
                            {renderListItem('representativePerson','Vorname der vertretenden Person', representativePerson.lastName,(e) => backToChallenge(e, 'representativePerson', 'lastName'))}
                            {renderListItem('representativePerson','Anschrift der vertretenden Person', representativePerson.address,(e) => backToChallenge(e, 'representativePerson', 'address'))}
                            {renderListItem('representativePerson','Hausnummer der vertretenden Person', representativePerson.houseNumber,(e) => backToChallenge(e, 'representativePerson', 'houseNumber'))}
                            {renderListItem('representativePerson','PLZ der vertretenden Person', representativePerson.zipCode,(e) => backToChallenge(e, 'representativePerson', 'zipCode'))}
                            {renderListItem('representativePerson','Ort der vertretenden Person', representativePerson.city,(e) => backToChallenge(e, 'representativePerson', 'city'))}
                            {renderListItem('representativePerson','Telefonnummer der vertretenden Person', representativePerson.phoneNumber,(e) => backToChallenge(e, 'representativePerson', 'phoneNumber'))}
                            {renderListItem('representativePerson','vertretende Person steht auch Dritten gegen Entgelt als PflegerIn zur Verfügung', representativePerson.personRepresenting)}
                            {renderListItem('representativePerson','vertretende Person ist gewerblich tätig als Pfleger', representativePerson.commerciallyActive)}
                            {renderListItem('representativePerson','Verwandtschaft oder Lebensgemeinschaft des Pflegebedürftigen zur vertretenden Person', representativePerson.relationshipList)}
                            {renderListItem('representativePerson','Verschwägerung des Pflegebedürftigen zur vertretenden Person', representativePerson.relationshipByMarriage)}
                            {renderListItem('representativePerson','Gleicher Haushalt zwischen des Pflegebedürftigen und vertretender Person', representativePerson.sameHousehold)}
                            {renderListItem('representativePerson','Beziehung', representativePerson.relationship)}
                        </ul>
                    </div>
                    }
                </div>
            </div> }
            {representativePerson.relationship &&
                (representativePerson.personRepresenting === 'Ja' &&
                representativePerson.commerciallyActive === 'Ja') &&
                (representationByCommerciallyActive.activeDates[0]?.durationOfAbsence || representationByCommerciallyActive.activeDates[0]?.durationOfAbsence === '-')
               && <div>
                <div>
                    <div>
                        <p>Vertretung durch gewerblich tätige Pflegeperson</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotateActive} className={cx({
                            [styles.rotate]: rotateActive
                        })}/>
                    </div>
                    {!!rotateActive && <div className={cx(styles.careReceipients)}>
                        <ul>
                            {renderListItem('representationByCommerciallyActive','Recipients Dates',Object.values(representationByCommerciallyActive)[0],Object.values(representationByCommerciallyActive)[0],(e) => backToChallenge(e, 'representationByCommerciallyActive', ''))}
                            {representationByCommerciallyActive.ConsentToTheData && (
                                <li>
                                    <p>
                                        Ich stimme der Datenverarbeitung, vor allem nach § 67 III SGB X zu.
                                    </p>
                                    <p><img src={Checke} alt="Checke" /></p>
                                </li>
                            )}
                            {representationByCommerciallyActive.iAgree && (
                                <li>
                                    <p>
                                        Ich habe alle Informationen freiwillig unter Zuhilfenahme der Dienste der FLEXXI Care Deutschland GmbH eingetragen und berechtige diese Firma, sie zum Zweck der Datenverarbeitung zu verwenden, insbesondere Ausdrucke für mich zu erstellen. Ich stelle FLEXXI Care von jeder Haftung, auch für fehlerhafte Übermittlung, frei. Ich habe vor Absendung die Verpflichtung, alle Unterlagen zu kontrollieren.
                                    </p>
                                    <p><img src={Checke} alt="Checke" /></p>
                                </li>
                            )}
                            {representationByCommerciallyActive.iHave && (
                                <li>
                                    <p>
                                        Ich stimme zu, dass die Daten in Erfüllung der Aufgaben nach § 94 I Nr. 3 SGB XI verarbeitet werden. Sie sind erforderlich zur Prüfung des Ansprchcs auf Verhinderungspflege nach § 39 SGB XI und erfordern die Mitwirkung nach § 60 SGB I. Mir ist bekannt, dass ohne Mitwirkung über den Anspruch nicht entschieden werden kann (§§ 33 I SGB XI, 60 I, 66 I SGB I). Mir ist bekannt, dass ich Allgemeine Informationen zur Datenverarbeitung und zu meinen Rechten von der Krankenkasse erhalten kann, wenn ich dies möchte. Die Angabe meiner Telefonnummer erfolgte freiwillig. Ich stimme zu, falls ich sie gegeben habe, dass ich bei Rückfragen telefonisch erreicht werden darf.
                                    </p>
                                    <p><img src={Checke} alt="Checke" /></p>
                                </li>
                            )}
                        </ul>
                    </div>
                    }
                </div>
            </div>}
            {(representationByPrivateIndividual.individualDates[0]?.durationOfAbsence || representationByPrivateIndividual.individualDates[0]?.durationOfAbsence === '-') && <div>
                <div>
                    <div>
                        <p>Vertretung durch private Person</p>
                        <img src={Arrow} alt={Arrow} onClick={handleRotatePrivate} className={cx({
                            [styles.rotate]: rotatePrivate
                        })}/>
                    </div>
                    {!!rotatePrivate && <div className={cx(styles.careReceipients)}>
                        <ul>
                            {renderListItem('representationByPrivateIndividual',Object.values(representationByPrivateIndividual)[0],Object.values(representationByPrivateIndividual)[0],(e) => backToChallenge(e, 'representationByPrivateIndividual', `individualGroup1`))}
                            {renderListItem('representationByPrivateIndividual','Sind Kosten für die Anreise angefallen ? Wählen sie aus der Liste', representationByPrivateIndividual.representingPersonByTransport,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'representingPersonByTransport'))}
                            {renderListItem('representationByPrivateIndividual','Wieviele km musste die Vertretung fahren ?', representationByPrivateIndividual.representingPersonByCar,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'representingPersonByCar'))}
                            {renderListItem('representationByPrivateIndividual','Von Postleitzahl und Stadt', representationByPrivateIndividual.zipCodeFrom,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'zipCodeFrom'))}
                            {renderListItem('representationByPrivateIndividual','Nach Postleitzahl und Stadt', representationByPrivateIndividual.zipCodeTo,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'zipCodeTo'))}
                            {renderListItem('representationByPrivateIndividual','Name des Kontoinhabers', representationByPrivateIndividual.NameOfAccountHolder,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'NameOfAccountHolder'))}
                            {renderListItem('representationByPrivateIndividual','IBAN', representationByPrivateIndividual.IBAN,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'IBAN'))}
                            {renderListItem('representationByPrivateIndividual','Bank', representationByPrivateIndividual.bank,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'bank'))}
                            {/*{renderListItem('representationByPrivateIndividual','Ich stimme der Datenverarbeitung, vor allem nach § 67 III SGB X zu.', representationByPrivateIndividual.ConsentToTheData,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'ConsentToTheData'))}*/}
                            {/*{renderListItem('representationByPrivateIndividual','Date',representationByPrivateIndividual.date === '-' ? '-' : representationByPrivateIndividual.date,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'date'))}*/}
                            {/*{renderListItem('representationByPrivateIndividual','Signature of the person in need of care or carer or representative', representationByPrivateIndividual.signatureOfThePerson,(e) => backToChallenge(e, 'representationByPrivateIndividual', 'signatureOfThePerson'))}*/}
                            {representationByPrivateIndividual.ConsentToTheData && (
                                <li>
                                    <p>
                                        Ich stimme der Datenverarbeitung, vor allem nach § 67 III SGB X zu.
                                    </p>
                                    <p><img src={Checked} alt="Checked" /></p>
                                </li>
                            )}
                            {representationByPrivateIndividual.iAgree && (
                                <li>
                                    <p>
                                        Ich habe alle Informationen freiwillig unter Zuhilfenahme der Dienste der FLEXXI Care Deutschland GmbH eingetragen und berechtige diese Firma, sie zum Zweck der Datenverarbeitung zu verwenden, insbesondere Ausdrucke für mich zu erstellen. Ich stelle FLEXXI Care von jeder Haftung, auch für fehlerhafte Übermittlung, frei. Ich habe vor Absendung die Verpflichtung, alle Unterlagen zu kontrollieren.
                                    </p>
                                    <p><img src={Checked} alt="Checked" /></p>
                                </li>
                            )}
                            {representationByPrivateIndividual.iHave && (
                                <li>
                                    <p>
                                        Ich stimme zu, dass die Daten in Erfüllung der Aufgaben nach § 94 I Nr. 3 SGB XI verarbeitet werden. Sie sind erforderlich zur Prüfung des Ansprchcs auf Verhinderungspflege nach § 39 SGB XI und erfordern die Mitwirkung nach § 60 SGB I. Mir ist bekannt, dass ohne Mitwirkung über den Anspruch nicht entschieden werden kann (§§ 33 I SGB XI, 60 I, 66 I SGB I). Mir ist bekannt, dass ich Allgemeine Informationen zur Datenverarbeitung und zu meinen Rechten von der Krankenkasse erhalten kann, wenn ich dies möchte. Die Angabe meiner Telefonnummer erfolgte freiwillig. Ich stimme zu, falls ich sie gegeben habe, dass ich bei Rückfragen telefonisch erreicht werden darf.
                                    </p>
                                    <p><img src={Checked} alt="Checked" /></p>
                                </li>
                            )}
                        </ul>
                    </div>
                    }
                </div>
            </div>}
            {notCareAnsweredIndex === 0 && <div></div>}
        </div>
    )
}
export default Details
