import React, {useEffect, useState} from "react";
import styles from './footer.module.scss'
import {useDispatch, useSelector} from "react-redux";
import cx from 'classnames'
import Skip from '../../../../icons/skipIcon.svg'
import {
    setAuthPerson, setAvailableDays,
    setCareQuizQuestions, setCommerciallyActive, setFormEdit,
    setLegalRepresentation, setPrivateIndividual,
    setRepresentativePerson
} from '../../../../redux/actions/quizQuestions';
import {
    setAuthorizedPerson,
    setCareReceipients, setCommercially, setIndividual,
    setLegalRepresentations, setRepresentative
} from "../../../../redux/actions/careReceipients";
import QuizCareReceipients from "../../../NestedComponents/Option 1/QuizCareReceipients";
import QuizLegalRepresentation from "../../../NestedComponents/Option 1/QuizLegalRepresentation";
import { createForm, createAuthUserForm, submitAuthUserForm, submitReimbursement } from '../../../../api/formApi';
import { setReimbursementRef } from '../../../../redux/actions/payment';
import AuthorizePerson from "../../../NestedComponents/Option 1/AuthorizePerson";
import QuizRepresentative from "../../../NestedComponents/Option 1/QuizRepresentative";
import QuizCommerciallyActive from "../../../NestedComponents/Option 1/QuizCommerciallyActive";
import QuizPrivateIndividual from "../../../NestedComponents/Option 1/QuizPrivateIndividual";
import {useNavigate} from "react-router-dom";
import { login } from '../../../../api/loginApi';
import Logo from "../../../../icons/miniLogo.svg";
const Footer = () => {
    const navigate = useNavigate()
    const [parentState, setParentState] = useState('')
    const { pathname } = window.location
    const {
        careReceipients ,
        legalRepresentations ,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual,
        nonActiveBtn: {activeBtn},
        authorizedPerson:{ periodOfAbsenceFrom, periodOfAbsenceTo},
        payment :{reimbursementRef}
    } = useSelector((state) => state)
    const email = useSelector((state) => state.userDetails).email
    const { quizCareReceipients:{quizCare,indexCare},
        quizLegalRepresentation:{quizLegal, indexLegal},
        quizAuthorizedPerson:{quizAuth,indexAuth},
        quizRepresentativePerson:{quizRep,indexRep},
        commerciallyActive:{quizActive,indexActive},
        privateIndividual:{quizPrivate,indexPrivate},
        chooseCard,
        editSummary,
        formEdit,
        availableDays
    } = useSelector((state) => state.quizQuestions)
    const [disabled, setDisabled] = useState(false)
    const dispatch = useDispatch()

    const isDisabledCare = quizCare.some((item, i) => i === indexCare && item.required === true)
    const isDisabledLegal = quizLegal.some((item, i) => i === indexLegal && item.required === true)
    const isDisabledAuth = quizAuth.some((item, i) => i === indexAuth && item.required === true)
    const isDisabledRep = quizRep.some((item, i) => i === indexRep && item.required === true)
    const isDisabledActive = quizActive.some((item, i) => i === indexActive && item.required === true)
    const isDisabledPrivate = quizPrivate.some((item, i) => i === indexPrivate && item.required === true)
    let notObjIndex = indexPrivate  - representationByPrivateIndividual.individualDates.length
    let notObjIndexActive = indexActive  - representationByCommerciallyActive.activeDates.length
    const [dates, setDates] = useState([]);
    const [currentIndexActive, setCurrentIndexActive] = useState(0);
    const [currentIndexIndividual, setCurrentIndexIndividual] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    useEffect(() => {
       setCurrentIndexActive(representationByCommerciallyActive.activeDates.length - 1)
    }, [representationByCommerciallyActive.activeDates.length]);

    useEffect(() => {
        setCurrentIndexIndividual(representationByPrivateIndividual.individualDates.length - 1)
    }, [representationByPrivateIndividual.individualDates.length]);
    useEffect(() => {
        authorizedPerson.periodOfAbsenceFrom && authorizedPerson.periodOfAbsenceTo && dispatch(setAvailableDays(differenceInDays));
    }, [authorizedPerson.periodOfAbsenceFrom,authorizedPerson.periodOfAbsenceTo]);
    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return date.toString();
    }
    const start = new Date(convertToDate(authorizedPerson.periodOfAbsenceFrom));
    const end = new Date(convertToDate(authorizedPerson.periodOfAbsenceTo));

    // Get the difference in time
    const differenceInTime = end - start + 1;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    // Function to generate the date range iteratively to avoid misordering
    const generateDateRange = (start, end) => {
        const startDate = new Date(start.split(".").reverse().join("-")); // Convert 'DD.MM.YYYY' to 'YYYY-MM-DD'
        const endDate = new Date(end.split(".").reverse().join("-"));
        const dateArray = [];

        let currentDate = startDate;
        while (currentDate <= endDate) {
            dateArray.push(formatDate(currentDate)); // Add formatted date
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return dateArray;
    };

    // Generate the date interval when the component mounts
    useEffect(() => {
        const startDate = convertToDate(periodOfAbsenceFrom);
        const endDate = convertToDate(periodOfAbsenceTo);
        const generatedDates = generateDateRange(startDate, endDate);
        setDates(generatedDates); // Set the generated dates in state
    }, [periodOfAbsenceFrom,periodOfAbsenceTo]);
    useEffect(() => {
    }, [careReceipients,
        legalRepresentations,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual]);

    const noLegal = {
        name: '',
        lastName: '',
        address: '',
        houseNumber: '',
        zipCode : '',
        city : '',
        phoneNumber: '',
        document: '',
    }
    const noActive = {
        activeDates:[],
        ConsentToTheData:'',
        iAgree:'',
        iHave:'',
    }
    const noIndividual = {
        individualDates: [ ],
        representingPersonByTransport:'',
        representingPersonByCar:'',
        zipCodeFrom:'',
        zipCodeTo:'',
        NameOfAccountHolder:'',
        IBAN:'',
        bank:'',
        date:'',
        ConsentToTheData:'',
        iAgree:'',
        iHave:'',
    }

    const handleSkip = () => {

        // Object.values(representationByCommerciallyActive)[0][notObjIndex + 1]?.availableDates.date && setCurrentIndex(currentIndex + 1)
        if(quizCare.length !== indexCare) {
            const newCareQuiz= quizCare.map((item , i)=> {
                if(i === indexCare){
                    return {
                        ...item,
                        answers: '-',
                        fileName: '-'
                    }

                }
                return item
            })
            dispatch(setCareReceipients(Object.keys(careReceipients)[indexCare],'-'))
            dispatch(setCareQuizQuestions(newCareQuiz,indexCare+1))
            setParentState('')
        }else{
            if(careReceipients.legalRepresentation === 'Ja' && quizLegal.length !== indexLegal){
                const newLegalQuiz = quizLegal.map((item , i)=> {
                    if(i === indexLegal){
                        return {
                            ...item,
                            answers: '-',
                            fileName: '-'

                        }
                    }
                    return item
                })
                dispatch(setLegalRepresentations(Object.keys(legalRepresentations)[indexLegal],'-'))
                dispatch(setLegalRepresentation(newLegalQuiz,indexLegal+1))
                setParentState('')
            }else{
                if(quizAuth.length !== indexAuth ){
                    const newAuthQuiz = quizAuth.map((item , i)=> {
                        if(i === indexAuth){
                            return {
                                ...item,
                                answers: '-'
                            }
                        }
                        return item
                    })
                    dispatch(setAuthorizedPerson(Object.keys(authorizedPerson)[indexAuth],'-'))
                    dispatch(setAuthPerson(newAuthQuiz,indexAuth+1))
                    setParentState('')
                }else {
                    if(quizRep.length !== indexRep) {
                        const newRepQuiz = quizRep.map((item , i)=> {
                            if(i === indexRep){
                                return {
                                    ...item,
                                    answers: '-'
                                }
                            }
                            return item
                        })
                        dispatch(setRepresentative(Object.keys(representativePerson)[indexRep],'-'))
                        dispatch(setRepresentativePerson(newRepQuiz,indexRep+1))
                        setParentState('')
                    }else{
                        if((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizActive.length !== indexActive) {
                            const newActiveQuiz = quizActive.map((item , i)=> {
                                if(i === indexActive){
                                    return {
                                        ...item,
                                        answers: typeof item.answers === 'object' ? {
                                            date:dates[currentIndexActive],
                                            duration: '0 Std.',
                                            amount:'€ 0'
                                        } : '-'
                                    }
                                }
                                return item
                            })

                            if(availableDays && availableDays > 0 && Object.values(representationByCommerciallyActive)[0].length !== availableDays && (Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence ||
                                Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence === '0') ) {
                                newActiveQuiz.splice(indexActive + 1, 0, {
                                        question: " War sie aus diesem Grund ganztags verhindert ?",
                                        // Select box (Less than 8 hours (default), More than 8 hours)
                                        answers: '',
                                        img: Logo,
                                        type: 'submit',
                                        required: false,
                                        questionKey: 'durationOfAbsence'
                                    },
                                    {
                                        answers: {
                                            date:'',
                                            duration:'',
                                            amount:''
                                        },
                                        img: Logo,
                                        type: 'group',
                                        required: false,
                                        questionKey: 'availableDates'
                                    });
                            }
                            !Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].durationOfAbsence ||
                            ( !Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].availableDates.amount ||
                                !Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0].length -1].availableDates.amount === 0) ?
                                dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[0],Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0]?.length -1].durationOfAbsence  ? {
                                        date: dates[currentIndexActive],
                                        duration: '0 Std.',
                                        amount:'€ 0'
                                    } : '-',Object.values(representationByCommerciallyActive)[0].length-1,
                                    Object.values(representationByCommerciallyActive)[0][Object.values(representationByCommerciallyActive)[0]?.length -1].durationOfAbsence ? 'availableDates': 'durationOfAbsence', availableDays) )
                                : dispatch(setCommercially(Object.keys(representationByCommerciallyActive)[representationByCommerciallyActive.activeDates.length === 1 ? indexActive : notObjIndexActive - 2],'-',null,null))

                            dispatch(setCommerciallyActive(newActiveQuiz,indexActive+1))

                            setParentState('')
                        }else{
                            if(((representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Ja' && representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Nein'&& representativePerson.relationship) ||
                                (representativePerson.personRepresenting === 'Nein' && representativePerson.commerciallyActive === 'Nein' && representativePerson.relationship) || (
                                    representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' &&
                                    (Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].availableDates.amount ||
                                        Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].availableDates.amount === 0)
                                )
                            ) && quizPrivate.length !== indexPrivate) {
                                const newPrivateQuiz = quizPrivate.map((item , i)=> {
                                    if(i === indexPrivate){
                                        return {
                                            ...item,
                                            answers: typeof item.answers === 'object' ? {
                                                date:dates[currentIndexIndividual],
                                                duration: '0 Std.',
                                                amount:'€ 0'
                                            } : '-'
                                        }
                                    }
                                    return item
                                })

                                if(availableDays && availableDays > 0 && Object.values(representationByPrivateIndividual)[0].length !== availableDays && (Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence ||
                                    Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence === '0') ) {
                                    newPrivateQuiz.splice(indexPrivate + 1, 0, {
                                            question: " War sie aus diesem Grund ganztags verhindert ?",
                                            // Select box (Less than 8 hours (default), More than 8 hours)
                                            answers: '',
                                            img: Logo,
                                            type: 'submit',
                                            required: false,
                                            questionKey: 'durationOfAbsence'
                                        },
                                        {
                                            answers: {
                                                date:'',
                                                duration:'',
                                                amount:''
                                            },
                                            img: Logo,
                                            type: 'group',
                                            required: false,
                                            questionKey: 'availableDates'
                                        });
                                }

                                !Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence ||
                               ( !Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].availableDates.amount ||
                                   !Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].availableDates.amount === 0)?
                                    dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[0],Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence  ? {
                                            date: dates[currentIndexIndividual],
                                            duration: '0 Std.',
                                            amount:'€ 0'
                                        } : '-',Object.values(representationByPrivateIndividual)[0].length-1,
                                        Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence ? 'availableDates': 'durationOfAbsence', availableDays) )
                                    : dispatch(setIndividual(Object.keys(representationByPrivateIndividual)[representationByPrivateIndividual.individualDates.length === 1 ? indexPrivate : notObjIndex - 2],'-',null,null))

                                dispatch(setPrivateIndividual(newPrivateQuiz,indexPrivate + 1))
                                setParentState('')
                            }
                        }
                    }
                }
            }
        }


    }
    const handleSubmit = () =>{
        let requestBody = {
            email: email,
            params:{
                careRecipients: careReceipients,
                legalRepresentations: careReceipients.legalRepresentation === 'Nein' ? noLegal : legalRepresentations,
                authorizedPerson:authorizedPerson,
                representativePerson:representativePerson,
                representationByCommerciallyActive: (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') ? representationByCommerciallyActive : noActive,
                representationByPrivateIndividual:(representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') ? noIndividual :representationByPrivateIndividual
            }
        }
        if(formEdit || localStorage.getItem('accessToken')) {
            requestBody.ref = reimbursementRef
        }
        setDisabled(true)
        if (localStorage.getItem('accessToken')) {
            createAuthUserForm(requestBody)
                .then((res)=> {
                    dispatch(setReimbursementRef(res.ref))
                    dispatch(setFormEdit(false))
                    return res.ref
                })
                .then((ref) => {
                    submitAuthUserForm({ ref })
                        .then(() =>{
                            // chooseCard === 'basic' ? navigate('/summary') : navigate('/payment')
                            navigate('/summary')
                        })
                        .catch((err) => console.log(err))
                        .finally(()=>setDisabled(false))

                })
                .catch((err) => console.log(err))

            return
        }
        // createForm({...requestBody, option: chooseCard === 'basic' ? 1 : chooseCard === 'pro' ? 2 : 3})
        createForm(requestBody)
            .then((res)=> {
             if(chooseCard === 'basic'){
                 const requestBody = {
                     ref: res.ref,
                     email: res.email,
                     // option: 1,
                 }
                 // submitReimbursement(requestBody)
                 //     .then(() => {})
                 //     .catch((err) => console.log(err))
                 //     .finally(()=>setDisabled(false))
             }
            dispatch(setFormEdit(false))
            dispatch(setReimbursementRef(res.ref))
            })
            .then(() => {
                chooseCard === 'basic'  ||  editSummary ? navigate('/summary') : navigate('/chooseData')
            })
            .catch((err) =>login(err))

    }

    return(

        <div className={cx(styles.footer,{
            [styles.challengeStep]: pathname === '/startChallenge',
        })}>
            {((representativePerson.personRepresenting !== 'Ja' && representativePerson.commerciallyActive !== 'Ja' && representationByPrivateIndividual.bank) ||
                (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja' && (indexActive === 2 * Number(availableDays) || (!availableDays && (representationByCommerciallyActive[representationByCommerciallyActive.length - 1]?.availableDates.amount ||
                    representationByCommerciallyActive[representationByCommerciallyActive.length - 1]?.availableDates.amount === 0) && representationByCommerciallyActive.length === 1))))
            && pathname === '/startChallenge'?
                <button className={cx(styles.submit,
                    {[styles.disabledBox]: representationByPrivateIndividual.bank && (representationByPrivateIndividual.ConsentToTheData === '' || representationByPrivateIndividual.iAgree === '' || representationByPrivateIndividual.iHave === '') || editSummary || disabled})}
                        onClick={handleSubmit} disabled={representationByPrivateIndividual.bank && (representationByPrivateIndividual.ConsentToTheData === '' || representationByPrivateIndividual.iAgree === '' || representationByPrivateIndividual.iHave === '') || editSummary || disabled}>
                    Abschicken</button> :
                pathname === '/startChallenge' ?
                    <div className={cx(styles.inputFooter,{
                        [styles.required]: isDisabledCare || isDisabledLegal || isDisabledAuth || isDisabledRep || isDisabledActive || isDisabledPrivate
                    })}>
                        <div>
                            <QuizCareReceipients parentState={parentState} setParentState={setParentState}/>
                            {careReceipients.legalRepresentation === 'Ja' && quizCare.length === indexCare  && <QuizLegalRepresentation parentState={parentState} setParentState={setParentState}/>}
                            {((careReceipients.legalRepresentation === 'Ja' && quizLegal.length === indexLegal) ||
                                (careReceipients.legalRepresentation === 'Nein' && quizCare.length === indexCare))  && <AuthorizePerson parentState={parentState} setParentState={setParentState}/>}
                            {(authorizedPerson.reasonForTheAbsence && quizAuth.length === indexAuth) && <QuizRepresentative parentState={parentState} setParentState={setParentState}/>}
                            {/*IF 56 and/or 57 YES:*/}
                            { ((representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') && quizRep.length === indexRep) &&
                                <QuizCommerciallyActive parentState={parentState} setParentState={setParentState} currentIndexActive={currentIndexActive}/>}
                            {/*IF 56, 57, 60 YES, and 58, 59 NOT UNRELATED*/}
                            {(((representativePerson.personRepresenting !== 'Ja' && representativePerson.commerciallyActive !== 'Ja')||(
                                    representativePerson.personRepresenting !== 'Ja' && representativePerson.commerciallyActive !== 'Nein') || (
                                    representativePerson.personRepresenting !== 'Nein' && representativePerson.commerciallyActive !== 'Ja'
                                )) && quizRep.length === indexRep) &&
                            <QuizPrivateIndividual parentState={parentState} setParentState={setParentState} currentIndexIndividual={currentIndexIndividual}/>}
                        </div>
                        {(pathname === '/startChallenge' && (
                                !isDisabledCare &&
                                !isDisabledLegal &&
                                !isDisabledAuth &&
                                !isDisabledRep &&
                                !isDisabledActive &&
                                !isDisabledPrivate
                            ))
                            &&
                            <button onClick={handleSkip}>
                                <p>
                                    Überspringen
                                </p>
                            </button>}
                    </div> :
                    <div>
                        <p>
                            © 2024 FLEXXI CARE - Alle Rechte vorbehalten
                        </p>
                    </div>
            }
        </div>
    )
}
export default Footer
