import React, {useState} from "react";
import styles from "../modalComponent.module.scss";
import FlexiiLogo from "../../../../../icons/flexiiLogo.svg";
import Button from "../../../../UiKitComponents/Button";
import {closeConfirmationModal} from "../../../../../redux/actions/confirmationModal";
import {setRepresentative} from "../../../../../redux/actions/careReceipients";
import {setEditFromSummary, setRepresentativePerson} from "../../../../../redux/actions/quizQuestions";
import {handlePhoneNumberFieldChange} from "../../../../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import {
    otherRelationshipList,
    relationshipByMarriageList,
    relationshipList
} from "../../../../../constants/lists/relationshipList";
import cx from "classnames";
import Arrow from "../../../../../icons/smallArrow.svg";
import PhoneNumberField from "../../../../ReusableField/PhoneNumberField";

const RepModalComponent = ({item, repIndex}) => {
    const { quizRepresentativePerson:{quizRep,indexRep} } = useSelector((state) => state.quizQuestions)
    const { representativePerson } = useSelector((state) => state)
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [answerRep, setAnswerRep] = useState(item.answers || '')
    const [repOption, setRepOption] = useState(false)

    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    const clickRepOption = () => {
        if(!repOption){
            setRepOption(true)
        }else{
            setRepOption(false)
        }
    }
    const clickOptionOfRep = (e) => {
        setAnswerRep(e.target.innerText)
        setRepOption(false)
    }
    const handleChange = (e) => {
        setAnswerRep(e.currentTarget.value)
    }
    const handleRepAnswer = (index) => {
         if(!answerRep) return
        const newQuiz= quizRep.map((item , i)=> {
            if(i === index){
                return {
                    ...item,
                    answers:  answerRep,
                    returnQuestion:( item.question === 'Ist die Vertretung gewerblich als Pflegefachkraft tätig oder sogar eine Pflegefirma ?' && answerRep === 'Nein') ?
                        'Vertretende Person ist privat und nur gelegentlich als PflegerIn tätig.' :
                      (  item.question === 'Ist die Vertretung mit dem Antragsteller verwandt ? Bitte wählen Sie aus der Liste.' && answerRep !== 'nicht verwandt') ?
                     'Ersatz von Kosten der Verhinderungspflege ist bei Personen in Verwandschaft, Lebensgemeinschaft und Ehepartnern nur eingeschränkt möglich.' :
                     (item.question === 'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.' && answerRep !== 'Nicht verheiratet oder verschwägert') ?
                     'Ersatz von Kosten der Verhinderungspflege ist bei Verschwägerten Personen nur eingeschränkt möglich.' :
                    item.question === 'Leben Vertretung und Antragsteller im selben Haushalt ?' && answerRep === 'Ja' ?
                        'Ersatz von Kosten der Verhinderungspflege ist bei dauernd im gleichen Haushalt lebenden Personen nur eingeschränkt möglich.' : ''
                }
            }
            return item
        })
        dispatch(setRepresentative(Object.keys(representativePerson)[index], Object.keys(representativePerson)[index] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${answerRep}` : answerRep))
        dispatch(setRepresentativePerson(newQuiz,indexRep))

        setAnswerRep('')
    }
    return (
        <section className={styles.modalComponent}>
            <p className={styles.odd}>
                <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
                <p>
                    {item.question}
                </p>
            </p>
            {item.type === 'phoneNumber' && <PhoneNumberField
                onChange={handleChangePhone}
                phoneNumberObj={phoneNumberObj}
                setAnswerr={setAnswerRep}
                value={answerRep}
            />}
            {item.type === 'option' && <div className={styles.optionHid}>
                    <div className={cx(styles.select,{
                        [styles.imgRotate]: !!repOption,
                        [styles.placeholder]:answerRep
                    })} onClick={clickRepOption} onKeyDown={(e) => {if (e.key === 'Enter') handleRepAnswer()}}>
                        <img src={Arrow} alt={Arrow}/>
                        {!!answerRep ? answerRep : item.question === 'Wie oder wo haben Sie die Vertretung gefunden?' ? representativePerson.relationship :
                        item.question === 'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.' ?
                        representativePerson.relationshipByMarriage : representativePerson.relationshipList
                        }
                    </div>
                    {!!repOption && <div className={styles.option}>
                        {item.question === 'Ist die Vertretung mit dem Antragsteller verwandt ? ' +
                        'Bitte wählen Sie aus der Liste. (Better : Ist die Vertretung mit (if possible: DEV to insert first name of caretaker)' +
                        ' verwandt ? Bitte wählen Sie aus der Liste.)' ?
                            relationshipList.map((item) => {
                            return (
                                <div onClick={clickOptionOfRep}>{item}</div>
                            )
                        }) : item.question === 'Ist die Vertretung mit dem Antragsteller verschwägert ? Bitte wählen Sie aus der Liste.'?
                            relationshipByMarriageList.map((item) => {
                                return (
                                    <div onClick={clickOptionOfRep}>{item}</div>
                                )
                            }) :  otherRelationshipList.map((item) => {
                                return (
                                    <div onClick={clickOptionOfRep}>{item}</div>
                                )
                            })
                        }
                    </div>}
                </div>
            }
            {item.type === 'submit' && (<span>
                                    <input type={'submit'} value={'Ja'}
                                           onClick={(e)=>handleChange(e)} className={styles.care}
                                    autoFocus={item.answers}
                                    />
                                   <input type={'submit'} value={'Nein'}
                                          onClick={(e)=>handleChange(e)}  className={styles.care}
                                   autoFocus={item.answers}
                                   />
                                    </span>)}
            <div>
                <Button  small primaryOutlined onClick={() => {
                    dispatch(closeConfirmationModal())
                    dispatch(setEditFromSummary(''))
                } }>
                    {'Abbrechen'}
                </Button>
                <Button
                    primary
                    small
                    onClick={() => {
                        handleRepAnswer(repIndex)
                        dispatch(closeConfirmationModal())
                        dispatch(setEditFromSummary(''))
                    }}
                >
                    {'Speichern'}
                </Button>
            </div>
        </section>
    )

}
export default RepModalComponent
