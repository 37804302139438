import {
    SET_USER_EMAIL,
    USER_LOGOUT,
    SET_USER_DETAILS,
    SET_SIGN_UP_SIGN_IN_RESET_STEP,
    VERIFICATION_CODE_CHANGE
} from '../../ActionTypes';

export const setUserEmail = (email) => ({
    type: SET_USER_EMAIL,
    email,
})

export const logOutUser = () => ({
    type: USER_LOGOUT,
})

export const setUserDetails = (data) => ({
    type: SET_USER_DETAILS,
    data,
})

export const setSignUpSignInResetStep = (
    path,
    step
) => ({
    type: SET_SIGN_UP_SIGN_IN_RESET_STEP,
    path,
    step,
})

export const setVerificationCode = (verificationCode) => ({
    type: VERIFICATION_CODE_CHANGE,
    verificationCode,
})
