import React, {useEffect, useRef, useState} from "react";
import CalendarIcon from "../../../../icons/calendar.svg";
import Calendar from "react-calendar";
import moment from "moment/moment";
import styles from "../../../Pages/Common/Footer/footer.module.scss";
import RightArrow from "../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../icons/leftArrowIcon.svg";
import AttachFile from "../../../../icons/attach.svg";
import {useDispatch, useSelector} from "react-redux";
import {setCareReceipients} from "../../../../redux/actions/careReceipients";
import {setCareQuizQuestions} from "../../../../redux/actions/quizQuestions";
import ArrowBtn from "../../../../icons/arrowBtn.svg";
import {insuranceCompanies } from "../../../../constants/lists/relationshipList";
import cx from "classnames";
import {uploadFile} from "../../../../api/formApi";
import PhoneNumberField from "../../../ReusableField/PhoneNumberField";
import {handlePhoneNumberFieldChange} from "../../../../redux/actions/Auth";
import {calculateAge, countryPhoneDetails} from "../../../../constant/constants";
import countryPickerData from "../../../../constant/countryPickerData";
const QuizCareReceipients = ({parentState,setParentState}) => {
    // const [answerr, setAnswerr] = useState('')
    const [fileName, setFileName] = useState('')
    const { quizCareReceipients:{quizCare,indexCare} } = useSelector((state) => state.quizQuestions)
    const { careReceipients , payment:{reimbursementRef}} = useSelector((state) => state)
    const { phoneNumberObj } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const [showCalendar, setShowCalendar] = useState(false)
    const [option, setOption] = useState(false)
    const wrapperRef = useRef(null);
    const wrapper = useRef(null);
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, []);
    useEffect(() => {
        document.addEventListener("click", handleClickOut, false);
        return () => {
            document.removeEventListener("click", handleClickOut, false);
        };
    }, []);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOption(false)
        }
    };
    const handleClickOut = event => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setShowCalendar(false)
        }
    };
    const handleChange = (e) => {
        setParentState(e.currentTarget.value)
    }
    const handleChangePhone = (newPhoneNumberObj) => {
        dispatch(handlePhoneNumberFieldChange(newPhoneNumberObj))
    }
    const clickOption = () => {
        if(!option){
            setOption(true)
        }else{
            setOption(false)
        }
    }

    const clickCareOption = (e) => {
        setParentState(e.target.innerText)
        setOption(false)
    }
    const handleFile = (e) =>  {
        const file = e.target.files[0]
        console.log(file);
        let formData = new FormData();
        formData.append('file', file);
        uploadFile(formData,reimbursementRef,'RC_CARE_RECIPIENT')
            .then((res)=> {
                console.log(res)
                setParentState(res.amazonUrl)
            })
            .catch((res)=> console.log(res))
        setFileName(file?.name)
    }
    const  handleChangeCalendar = (e) =>{
        setParentState(moment(e).format('DD.MM.YYYY'))
        setShowCalendar(false)
    }
    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return date.toString();
    }
    const handleCareAnswer = () => {
        if(!parentState) return
        const newQuiz= quizCare.map((item , i)=> {
            if(i === indexCare){
                return {
                    ...item,
                    answers: parentState,
                    returnQuestion: item.question === "Wurde bereits ein Pflegegrad erteilt ? Wenn ja, welcher ? Bitte wählen Sie aus der Liste aus."
                    && ( parentState === 'Nein' || parentState === '1') ?
                        'Ersatz von Kosten der Verhinderungspflege ist derzeit nicht möglich, mindestens Pflegegrad 2 ist erforderlich.' :
                        calculateAge(convertToDate(parentState)) < 25 ?
                        'Bitte beachten Sie : Bei Antragstellern mit Pflegegrad 4 oder 5 bis einschliesslich 25 Jahre kann die Berechnung Fehler aufweisen.' : '',
                    fileName: fileName
                }
            }
            return item
        })
        dispatch(setCareReceipients(Object.keys(careReceipients)[indexCare], Object.keys(careReceipients)[indexCare] === 'phoneNumber' ? `+${phoneNumberObj.callingCode} `+``+`${parentState}` : parentState,))
        // dispatch(setIndex(index + 1))
        dispatch(setCareQuizQuestions(newQuiz,indexCare + 1))
        dispatch(handlePhoneNumberFieldChange({...phoneNumberObj,number:''}))
        setParentState('')
    }

    const handleShowCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }

    return (
        <>
            {quizCare?.map((item,i)=>{
                if(i === indexCare) {
                    switch (item?.type) {
                        case 'text':
                            return  <input maxLength={item.questionKey === 'insuranceNumber' ? "16" : null} type={'text'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={parentState}
                                           onChange={(e)=>handleChange(e)}
                                           onKeyDown={(e) => {
                                               if (e.key === 'Enter') handleCareAnswer()}} key={i} autoFocus={true}/>
                        case 'number':
                            return  <input type={ item.questionKey === 'insuranceNumber' ? 'text' : 'number'} pattern="" placeholder={'Geben Sie eine Antwort ein.'} value={parentState}
                                           onChange={(e)=>handleChange(e)} onInput={(e) =>
                                item.questionKey === 'insuranceNumber' ?  e.currentTarget.value = e.currentTarget.value.slice(0, 16) :  e.currentTarget.value = e.currentTarget.value.slice(0, 5)} onKeyDown={(e) => {
                                if (e.key === 'Enter') handleCareAnswer()}} key={i} autoFocus={true}/>
                        case 'phoneNumber':
                            return <PhoneNumberField
                                onChange={handleChangePhone}
                                phoneNumberObj={phoneNumberObj}
                                setAnswerr={setParentState}
                            />
                        case 'date':
                            return (<div>
                                <input placeholder="Type date" value={!parentState ?
                                    moment(new Date(1965,moment().month(),moment().date())).format('DD.MM.YYYY')
                                    : moment(convertToDate(parentState)).format('DD.MM.YYYY')} required
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') handleCareAnswer()}} key={i} autoFocus={true} onClick={handleShowCalendar}
                                       className={cx( {[styles.bDay]:parentState})}  readOnly={true}/>
                                <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowCalendar}/>
                                {!!showCalendar && <Calendar
                                    onChange={(e) => handleChangeCalendar(e)}
                                    value={!parentState ? new Date(1965,moment().month(),moment().date()) : convertToDate(parentState)}
                                    className={styles.reactCalendar}
                                    nextLabel={<img src={RightArrow} alt={'next'}/>}
                                    prevLabel={<img src={LeftArrow} alt={'Previous'}/>}
                                    next2Label={null}
                                    prev2Label={null}
                                    locale="de"
                                />}
                            </div>)
                        case 'option':
                            return  <div className={styles.optionHid}>
                                        <div className={cx(styles.select,{[styles.placeholder]:parentState})} onClick={clickOption} onKeyDown={(e) => {
                                            if (e.key === 'Enter') handleCareAnswer()}} key={i}  ref={wrapperRef}>
                                            {!!parentState ? parentState : 'Bitte wählen Sie'}
                                        </div>
                                        {!!option && <div className={styles.option} >
                                            {insuranceCompanies.sort().map((item) => {
                                                return (
                                                    <div onClick={clickCareOption}>{item}</div>
                                                )
                                            })}
                                        </div>}
                                   </div>
                        case 'rate':
                            return  (
                                <>
                                    <input type={'submit'} value={'Nein'}
                                           onClick={(e)=>handleChange(e)}/>
                                    <input type={'submit'} value={1}
                                           onClick={(e)=>handleChange(e)}/>
                                    <input type={'submit'} value={2}
                                           onClick={(e)=>handleChange(e)}/>
                                    <input type={'submit'} value={3}
                                           onClick={(e)=>handleChange(e)}/>
                                    <input type={'submit'} value={4}
                                           onClick={(e)=>handleChange(e)}/>
                                    <input type={'submit'} value={5}
                                           onClick={(e)=>handleChange(e)}/>
                                </>
                            )
                        case 'submit':
                            return (<span>
                                    <input type={'submit'} value={'Ja'}
                                           onClick={(e)=>handleChange(e)} key={i} className={styles.care} />
                                   <input type={'submit'} value={'Nein'}
                                          onClick={(e)=>handleChange(e)} key={i} className={styles.care} />
                                    </span>)
                        case 'file':
                            return  <>
                                    <span className={styles.imageUpload} >
                                        <label htmlFor="file-input">
                                            <img src={AttachFile} alt={AttachFile} />
                                        </label>
                                        <input
                                            id="file-input"
                                            type="file"
                                            onChange={(e)=> handleFile(e)}
                                            accept="image/jpeg,image/png,.pdf"
                                        />
                                    </span>
                                <input type={'text'} placeholder={'Attach a file'} value={fileName} className={styles.attach} readOnly={true}/>
                            </>

                    }
                }
            })
            }
            {quizCare.length !== indexCare && <button disabled={!parentState} className={cx({ [styles.btnDisabled]: !parentState })} onClick={handleCareAnswer}>
                <img src={ArrowBtn} alt={ArrowBtn} />
            </button>}
        </>
    )
}

export default QuizCareReceipients
