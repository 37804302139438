import React from 'react'
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthHOC = (props) => {
    const location = useLocation()
    const pathname = location.pathname
    const queryParams = new URLSearchParams(location.search);
    const refFromParams = queryParams.get('ref');
    const ref = useSelector((state) => state.payment).reimbursementRef || refFromParams
    const email = useSelector((state) => state.userDetails).email

    if ( ref && pathname === '/set-password') {
        return props.children
    }
    if (localStorage.getItem('accessToken') && pathname === '/settings') {
        return props.children
    }
    if (pathname === '/' || (pathname !== '/' && pathname !== '/profile' && (!email || !ref))){
        if (localStorage.getItem('accessToken')) {
            return  <Navigate to='/profile' />
        }
    }
    if (
        !localStorage.getItem('accessToken') && pathname !== '/' && !email && !ref) {
        return <Navigate to="/" />
    }
    return props.children
}

export default AuthHOC
