const relationshipList = [
    'nicht verwandt',
    'Ehepartner',
    'Verwandt 1 Grad: Vater, Mutter, Sohn oder, Tochter des Berechtigten',
    'Verwandt 2. Grades: Schwester, Bruder, Grossmutter, Grossvater, Enkeltochter oder Enkelsohn des Berechtigten',
    'eingetragener Lebenspartner',
    'andere Verwandtschaft'
]

const reasonsOfAbsence =[
    "Ruhetag (zB wegen Erschöpfung, religiöser Feiertag)",
    "Erhlolungsurlaub",
    "Krankenhaus-/Reha/Kuraufenthalt",
    "Dienstreise",
    "eigene längere Krankheit",
    "Krankheit einer nahestehenden Person",
    "eigene kurzfristige Krankheit",
    "dienstliche Verpflichtung",
    "Arztbesuch",
    "Private Abwesenheit (Theaterbesuch, Familienfeier, Frisör) mit positiver Wirkung auf das eigene Allgemeinbefinden oder das einer dritten Person",
    "sonstige, moralisch erforderliche Abwesenheit, die einem Dritten oder der Pflegenden Person zur Hilfe dient",
    "Besuch einer Fortbildung"
];
// Nicht verschwägert

const relationshipByMarriageList = [
    "Nicht verheiratet oder verschwägert",
    "Ehepartner oder eingetragener Lebenspartner",
    "Verschwägert 1a) Ehepartner / Lebenspartner von Vater Mutter Sohn Tochter des Berechtigten",
    "Verschwägert 1b) Vater Mutter Sohn Tochter des Ehepartner / Lebenspartners des Berechtigten",
    "Verschwägert 2a) Ehepartner von Schwester Bruder Grossmutter Grossvater Enkeltochter Enkelsohn des Berechtigten",
    "Verschwägert 2b) Schwester Bruder Grossmutter Grossvater Enkeltochter Enkelsohn des Ehepartners / Lebenspartners des Berechtigten",
    "Anders verwandt oder verschwägert"
];

const otherRelationshipList = [
    "durch Dritte vermittelte Pflege, zB FLEXXI Pflegekraft",
    "Pflegefirma",
    "Nachbar, auch wenn im selben Haus aber nicht im gleichen Haushalt lebend",
    "Anderer Bekannter oder Nachbar",
    "Verwandt oder Verschwägert"
]
const insuranceCompanies = [
    "Allianz Private Krankenversicherungs-AG",
    "ALTE OLDENBURGER Krankenversicherung von 1927 V.V.a.G",
    "AOK - Die Gesundheitskasse für Niedersachsen",
    "AOK - Die Gesundheitskasse in Hessen",
    "AOK Baden-Württemberg",
    "AOK Bayern - Die Gesundheitskasse",
    "AOK Bremen / Bremerhaven",
    "AOK Nordost - Die Gesundheitskasse",
    "AOK NordWest - Die Gesundheitskasse",
    "AOK PLUS - Die Gesundheitskasse für Sachsen und Thüringen",
    "AOK Rheinland-Pfalz/Saarland-Die Gesundheitskasse",
    "AOK Rheinland/Hamburg - Die Gesundheitskasse",
    "AOK Sachsen-Anhalt - Die Gesundheitskasse",
    "Arag ARAG Krankenversicherungs-AG",
    "Astra Versicherung AG",
    "Audi BKK",
    "AXA Krankenversicherung",
    "BA die Bayerische Allgemeine Versicherung AG",
    "BAHN-BKK",
    "Barmenia Krankenversicherung AG",
    "BARMER",
    "Bayerische Beamtenkrankenkasse Aktiengesellschaft",
    "BERGISCHE KRANKENKASSE",
    "Bertelsmann BKK",
    "Betriebskrankenkasse der G.M. Pfaff AG",
    "Betriebskrankenkasse EWE",
    "Betriebskrankenkasse Miele",
    "Betriebskrankenkasse Mobil",
    "Betriebskrankenkasse PricewaterhouseCoopers",
    "Betriebskrankenkasse Technoform",
    "BIG direkt gesund",
    "BKK Akzo Nobel Bayern",
    "BKK B. Braun Aesculap",
    "BKK Deutsche Bank AG",
    "BKK Diakonie",
    "BKK EUREGIO",
    "BKK evm",
    "BKK exklusiv",
    "BKK Faber-Castell & Partner",
    "BKK firmus",
    "BKK Freudenberg",
    "BKK GILDEMEISTER SEIDENSTICKER",
    "BKK Groz-Beckert",
    "BKK Herkules",
    "BKK Linde",
    "BKK MAHLE",
    "bkk melitta hmr",
    "BKK mkk - meine krankenkasse",
    "BKK MTU",
    "BKK Pfalz",
    "BKK ProVita",
    "BKK Public",
    "BKK Rieker.RICOSTA.Weisser",
    "BKK Salzgitter",
    "BKK Scheufelen",
    "BKK Schwarzwald-Baar-Heuberg",
    "BKK Textilgruppe Hof",
    "BKK VDN",
    "BKK VerbundPlus",
    "BKK Voralb HELLER*INDEX*LEUZE",
    "BKK WERRA-MEISSNER",
    "BKK WIRTSCHAFT UND FINANZEN",
    "BKK ZF & Partner",
    "BKK_DürkoppAdler",
    "BKK-Würth",
    "BKK24",
    "BMW BKK",
    "Bosch BKK",
    "CONCORDIA Krankenversicherungs-Aktiengesellschaft",
    "Continentale Betriebskrankenkasse",
    "Continentale Krankenversicherung a.G.",
    "DA direkt Deutsche Allgemeine Versicherung AG",
    "DAK-Gesundheit",
    "Debeka BKK",
    "Debeka Krankenversicherungsverein auf Gegenseitigkeit",
    "DEVK Krankenversicherungs-Aktiengesellschaft",
    "DFV Deutsche Familienversicherung AG",
    "DKV Deutsche Krankenversicherung AG",
    "energie-Betriebskrankenkasse",
    "ENVIVAS Krankenversicherung AG",
    "ERGO Krankenversicherung AG",
    "Ernst & Young BKK",
    "Europ Assistance SA",
    "EUROPA Versicherung AG",
    "FREIE ARZT- UND MEDIZINKASSE der Angehörigen der Berufsfeuerwehr und der Polizei VVaG",
    "Generali Deutschland Krankenversicherung AG",
    "GOTHAER KRANKENVERSICHERUNG AG",
    "HALLESCHE Krankenversicherung auf Gegenseitigkeit",
    "Handelskrankenkasse (hkk)",
    "HanseMerkur Krankenversicherung AG",
    "HanseMerkur Speziale Krankenversicherung AG",
    "Heimat Krankenkasse",
    "HEK - Hanseatische Krankenkasse",
    "HUK-COBURG Krankenversicherung AG",
    "IDEAL Versicherung AG",
    "IKK - Die Innovationskasse",
    "IKK classic",
    "IKK gesund plus",
    "IKK Südwest",
    "INNUNGSKRANKENKASSE BRANDENBURG UND BERLIN",
    "INTER Krankenversicherung AG",
    "Janitos Versicherung AG",
    "KARL MAYER BKK",
    "Kaufmännische Krankenkasse - KKH",
    "KNAPPSCHAFT",
    "Koenig & Bauer BKK",
    "Krones Betriebskrankenkasse",
    "KUK Krankenunterstützungskasse Hannover (KUK Hannover)",
    "KVB Krankenversorgung der Bundesbahnbeamten",
    "Landeskrankenhilfe V.V.a.G.",
    "LIGA Krankenversicherung katholischer Priester VVaG",
    "LVM Krankenversicherungs-AG",
    "Mecklenburgische Krankenversicherungs-AG",
    "Mercedes-Benz BKK",
    "Merck BKK",
    "mhplus Betriebskrankenkasse",
    "Münchener Verein Versicherungsgruppe",
    "Novitas BKK",
    "NÜRNBERGER Krankenversicherung AG",
    "ottonova Krankenversicherung AG",
    "PBeaKK Postbeamtenkrankenkasse Körperschaft des öffentlichen Rechts (KdöR)",
    "Pronova BKK",
    "Provinzial Krankenversicherung Hannover AG",
    "R + V Krankenversicherung AG",
    "R+V Betriebskrankenkasse",
    "Salus BKK",
    "SDK Süddeutsche Krankenversicherung a.G.",
    "SECURVITA BKK",
    "Siemens-Betriebskrankenkasse (SBK)",
    "SIGNAL IDUNA Krankenversicherung a.G.",
    "SKD BKK",
    "SONO Krankenversicherung a.G",
    "Sozialversicherung für Landwirtschaft, Forsten und Gartenbau (SVLFG)",
    "St. Martinus Priesterverein der Diözese Rottenburg-Stuttgart – Kranken- und Sterbekasse (KSK) – VVaG",
    "Südzucker BKK",
    "Techniker Krankenkasse",
    "TUI BKK",
    "Union Krankenversicherung AG",
    "uniVersa Krankenversicherung a.G.",
    "Versicherer im Raum der Kirchen Krankenversicherung AG",
    "VIACTIV Krankenkasse",
    "vigo Krankenversicherung VVaG",
    "vivida bkk",
    "WGV WGV-Versicherung AG",
    "WMF Betriebskrankenkasse",
    "Württembergische Krankenversicherung AG",
    "Würzburger Versicherungs-AG"
];


export {
    relationshipList,
    reasonsOfAbsence,
    relationshipByMarriageList,
    otherRelationshipList,
    insuranceCompanies
}
