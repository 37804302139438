import React, { useState } from 'react'
import styles from './accountDropDown.module.scss'
import ProfileIcon from '../../../icons/profileIcon.svg'
import { useSelector } from 'react-redux'
import ArrowIcon from '../../../icons/arrow.svg'
import AccountPickList from './AccountPickList'
import OutsideClickHandler from 'react-outside-click-handler'
// import moment from 'moment'
// import { ISO_8601 } from '../../../constants/constants'

const AccountDropDown = () => {
    const [pickListOpened, setPickListOpened] = useState(false)
    // const userDetails = useSelector((state) => state.userDetails)
    const { selectedLanguage } = useSelector((state) => state.metadata)
    const userData = useSelector((state) => state.userDetails)

    // const { fullName, createdDate, profileUrl } = userDetails
    // const joinedDate = moment(createdDate, ISO_8601)
    //     .locale(selectedLanguage)
    //     .format('MMMM YYYY')

    return (
        <OutsideClickHandler onOutsideClick={() => setPickListOpened(false)}>
            <button
                className={styles.accountDropDown}
                onClick={() => setPickListOpened(!pickListOpened)}
            >
                <div>
                    <img src={ProfileIcon} alt={'ProfileIcon'} />
                    {(userData.option === 2 || userData.option === 3) &&
                        <div className={styles.userType}>{userData.option === 2 ? 'PRO' : userData.option === 3 ? 'PREMIUM': ''}</div>}
                </div>

                {/*<div className={styles.nameSurnameDateWrapper}>*/}
                {/*    <span>{fullName}</span>*/}
                {/*    <span>{t('joined')} {joinedDate}</span>*/}
                {/*</div>*/}
                <img src={ArrowIcon} alt={'ArrowDownIcon'} />
                {pickListOpened && <AccountPickList />}
            </button>
        </OutsideClickHandler>
    )
}

export default AccountDropDown
