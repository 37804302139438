import React from "react";
import './App.css';
import PageComponent from "./components/Pages/PageComponent";
import StartChallenge from "./components/NestedComponents/StartChallenge";
import ChallengeSteps from "./components/NestedComponents/Option 1/ChallengeSteps";
import ChooseYourData from "./components/NestedComponents/Option 1/ChooseYourData";
import Payment from './components/Pages/Payment';
import PaymentResult from './components/Pages/Payment/PaymentResult';
import {Route, Routes} from "react-router-dom";
import SummaryDetails from "./components/NestedComponents/Option 1/SummaryDetails";
import Profile from './components/NestedComponents/Option 2/Profile';
import AuthHOC from './components/AuthHOC';
import Settings from './components/Pages/Settings';
import ResetPassword from './components/Pages/SignUpSignIn/ResetPassword';

function App() {

    return (
        <div className="App">
            <Routes>
                <Route exact path='/' element={<AuthHOC><StartChallenge/></AuthHOC>} />
                <Route exact path="/resetpassword" element={<AuthHOC><ResetPassword /></AuthHOC>} />
                <Route exact path='/startChallenge' element={<AuthHOC><ChallengeSteps/></AuthHOC>} />
                <Route exact path='/chooseData' element={<AuthHOC><ChooseYourData/></AuthHOC>} />
                <Route exact path='/summary' element={
                    <AuthHOC>
                        <PageComponent>
                            <SummaryDetails />
                        </PageComponent>
                    </AuthHOC>
                } />
                <Route exact path="/payment" element={<AuthHOC><Payment /></AuthHOC>} />
                <Route exact path="/set-password" element={<AuthHOC><PaymentResult /></AuthHOC>} />
                <Route exact path="/profile" element={<AuthHOC><Profile /></AuthHOC>} />
                <Route exact path="/settings" element={<AuthHOC><Settings /></AuthHOC>} />
            </Routes>
        </div>
  );
}

export default App;
