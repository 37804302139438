import React, {useState} from "react";
import styles from "../modalComponent.module.scss";
import FlexiiLogo from "../../../../../icons/flexiiLogo.svg";
import Calendar from "react-calendar";
import moment from "moment/moment";
import RightArrow from "../../../../../icons/rightArrowIcon.svg";
import LeftArrow from "../../../../../icons/leftArrowIcon.svg";
import Button from "../../../../UiKitComponents/Button";
import {closeConfirmationModal} from "../../../../../redux/actions/confirmationModal";
import {setCommercially, setIndividual} from "../../../../../redux/actions/careReceipients";
import {
    setEditFromSummary,
    setPrivateIndividual
} from "../../../../../redux/actions/quizQuestions";
import {useDispatch, useSelector} from "react-redux";
import CalendarIcon from "../../../../../icons/calendar.svg";
import Time from "../../../../../icons/time.svg";
import Amount from "../../../../../icons/amount.svg";
import cx from "classnames";


const AuthModalComponent = ({ item ,individualIndex}) => {
    const { privateIndividual:{quizPrivate,indexPrivate} ,availableDays} = useSelector((state) => state.quizQuestions)
    const { representationByPrivateIndividual,authorizedPerson:{ periodOfAbsenceFrom, periodOfAbsenceTo} } = useSelector((state) => state)
    const dispatch = useDispatch()
    const [answerPrivate, setAnswerPrivate] = useState(item.type !== 'date' ? item.answers.date || '' : '')
    const [duration, setDuration] = useState(item.answers?.duration ? parseInt(item.answers?.duration[0], 10) : 0 || '')
    const [amount, setAmount] = useState(item.answers.amount?.replace(/[^\d]/g, '')|| '')
    const [showCalendar, setShowCalendar] = useState(false)
    let notObjIndex = indexPrivate  - representationByPrivateIndividual.individualDates.length
    let activeDatesObj =  Object.values(representationByPrivateIndividual)[0].findIndex(itemOfObj =>
        itemOfObj.availableDates?.date === item.answers?.date &&
        itemOfObj.availableDates?.duration === item.answers?.duration &&
        itemOfObj.availableDates?.amount === item.answers?.amount
    )
    let activeDatesAbsence = Object.values(representationByPrivateIndividual)[0].findIndex(itemOfObj =>
        itemOfObj.durationOfAbsence === item.answers
    )
    const  handleChangePrivateCalendar = (e) =>{
        setAnswerPrivate(moment(e).format('DD.MM.YYYY'))
        setShowCalendar(false)
    }
    const convertToDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        const date = new Date(year, month - 1, day);
        return date.toString();
    }
    const handleShowPrivateCalendar = () => {
        if(!showCalendar) {
            setShowCalendar(true)
        }else{
            setShowCalendar(false)
        }
    }
    const handleChangeDuration = (e) => {
        setDuration(e.currentTarget.value)
    }
    const handleChangeAmount = (e) => {
        setAmount(e.currentTarget.value)
    }

    const handleChange = (e) => {
        setAnswerPrivate(e.currentTarget.value)
    }
    const convertMinutesToHours = (duration) => {
        const hours = Math.ceil(duration / 60)
        return hours;
    }
    const handlePrivateAnswer = (index) => {
        console.log('fromModal',index);
        const newQuiz= quizPrivate.map((item , i)=> {
            if(i === index){
                return {
                    ...item,
                    answers: typeof item.answers === 'object' ? {
                        ...item.answers,
                        date: answerPrivate ? answerPrivate : item.answers.date,
                        duration: duration ? `${duration} Std.` : item.answers.duration,
                        amount: amount ? `€ ${amount}`: item.answers.duration
                    } : answerPrivate
                }
            }
            return item
        })

        item.questionKey === "durationOfAbsence"  || item.questionKey === 'availableDates' ?
            dispatch(setCommercially(Object.keys(representationByPrivateIndividual)[0],amount ? {
                date: answerPrivate,
                duration: duration ? `${duration} Std.` : item.answers.duration,
                amount: amount ? `€ ${amount}`: item.answers.duration
            } : answerPrivate,typeof  item.answers === "object" ? activeDatesObj : activeDatesAbsence,amount ? 'availableDates': 'durationOfAbsence', availableDays) ) :
            dispatch(setCommercially(Object.keys(representationByPrivateIndividual)[representationByPrivateIndividual.activeDates.length === 1 ? indexPrivate : notObjIndex - 2],answerPrivate,null,null))


        dispatch(setPrivateIndividual(newQuiz,indexPrivate))
        setAnswerPrivate('')
    }

    return <section className={styles.modalComponent}>
        <p className={styles.odd}>
            <img src={item?.img && FlexiiLogo} alt={FlexiiLogo}/>
            <p>
                {item.question}
            </p>
        </p>
        {item.type === 'submit' && (
              <span>
                <input type={'submit'} value={'Ja'}
                       onClick={(e)=>handleChange(e)} className={styles.care}
                       autoFocus={Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence === 'Ja'}/>
                <input type={'submit'} value={'Nein'}
                       onClick={(e)=>handleChange(e)} className={styles.care}
                       autoFocus={Object.values(representationByPrivateIndividual)[0][Object.values(representationByPrivateIndividual)[0].length -1].durationOfAbsence=== 'Nein'}/>
                </span>
            )
        }
        {item.type === 'group' && (
            <div className={styles.group}>
                <div>
                    <input placeholder="Datum" value={answerPrivate}  readOnly={true}/>
                    <img src={CalendarIcon} alt={CalendarIcon} onClick={handleShowPrivateCalendar}/>
                </div>
                <div>
                    <input required placeholder={'Dauer'} value={duration} type={'number'} pattern=""
                           onInput={(e) => e.currentTarget.value = e.currentTarget.value.slice(0, 2)}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') handlePrivateAnswer()}}
                           onChange={(e)=>handleChangeDuration(e)} autoFocus={true}/>
                    <img src={Time} alt={Time} />
                </div>

                <div>
                    <input type={'number'}  placeholder={'Betrag'} value={amount}
                           onChange={(e)=>handleChangeAmount(e)}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') handleChangeAmount(e)}}  autoFocus={true}/>
                    <img src={Amount} alt={Amount} />
                </div>
            </div>
        )}

        <div>
            <Button  small primaryOutlined onClick={() => {
                dispatch(closeConfirmationModal())
                dispatch(setEditFromSummary(''))
            } }>
                {'Abbrechen'}
            </Button>
            <Button
                primary
                small
                onClick={() => {
                    handlePrivateAnswer(individualIndex)
                    dispatch(closeConfirmationModal())
                    dispatch(setEditFromSummary(''))
                }}
            >
                {'Speichern'}
            </Button>
        </div>
    </section>
}
export default AuthModalComponent
