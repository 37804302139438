import { PHONE_NUMBER_FIELD_CHANGE, SET_SIGN_UP_SIGN_IN_RESET_STEP, VERIFICATION_CODE_CHANGE } from '../../ActionTypes'
import { auth } from '../../defaultStates'

export default (state = auth, action = {}) => {
  switch (action.type) {
    case PHONE_NUMBER_FIELD_CHANGE:
      return {
        ...state,
        phoneNumberObj: action.phoneNumberObj,
      }
    case SET_SIGN_UP_SIGN_IN_RESET_STEP:
      return {
        ...state,
        [action.path]: action.step,
      }
    case VERIFICATION_CODE_CHANGE:
      return {
        ...state,
        verificationCode: action.verificationCode,
      }
    default:
      return state
  }
}
