import React from 'react';
import styles from './chooseYourData.module.scss'
import Robotik from '../../../../icons/robbot.svg';
import MiniLogo from '../../../../icons/lightMiniLogo.svg'
import PurplleCheck from '../../../../icons/purplleCircle.svg'
import LightCheck from '../../../../icons/lightCircle.svg'
import {Link} from "react-router-dom";
import PageComponent from "../../../Pages/PageComponent";
import cx from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {setChooseCard, setFormEdit} from "../../../../redux/actions/quizQuestions";
import isProduction from "../../../../utils/helpers/isProduction";
import CrossIcon from '../../../../icons/cross.svg'
import {createForm, submitReimbursement} from "../../../../api/formApi";
import {setReimbursementRef} from "../../../../redux/actions/payment";
import {login} from "../../../../api/loginApi";

const ChooseYourData = () => {
    const dispatch = useDispatch()
    const {
        careReceipients ,
        legalRepresentations ,
        authorizedPerson,
        representativePerson,
        representationByCommerciallyActive,
        representationByPrivateIndividual,
        nonActiveBtn: {activeBtn},
        payment :{reimbursementRef}
    } = useSelector((state) => state)
    const email = useSelector((state) => state.userDetails).email
    const noLegal = {
        name: '',
        lastName: '',
        address: '',
        houseNumber: '',
        zipCode : '',
        city : '',
        phoneNumber: '',
        document: '',
    }
    const noActive = [
        {
            durationOfAbsence:'',
            availableDates : {
                date: '',
                duration: '',
                amount:''
            }
        }
    ]
    const handleChooseCard = (cardType) => {
        dispatch(setChooseCard(cardType))
        let requestBody = {
            ref: reimbursementRef,
            email: email,
            params:{
                careRecipients: careReceipients,
                legalRepresentations: careReceipients.legalRepresentation === 'Nein' ? noLegal : legalRepresentations,
                authorizedPerson:authorizedPerson,
                representativePerson:representativePerson,
                representationByCommerciallyActive: (representativePerson.personRepresenting === 'Ja' && representativePerson.commerciallyActive === 'Ja') ? representationByCommerciallyActive : noActive,
                representationByPrivateIndividual:representationByPrivateIndividual
            }
        }
        createForm({...requestBody, option: cardType === 'basic' ? 1 : cardType === 'pro' ? 2 : 3})
            .then((res)=> {
                if(cardType === 'basic'){
                    const requestBody = {
                        ref: res.ref,
                        email: res.email,
                        option: 1,
                    }
                    submitReimbursement(requestBody)
                        .then(() => {})
                        .catch((err) => console.log(err))
                        // .finally(()=>setDisabled(false))
                }
                dispatch(setFormEdit(false))
                dispatch(setReimbursementRef(res.ref))
            })
            // .then(() => {
            //     cardType === 'basic'  ||  editSummary ? navigate('/summary') : navigate('/chooseData')
            // })
            .catch((err) =>login(err))
    }
    console.log(window.innerHeight);
    return (
        <PageComponent>
            <div className={styles.chooseData}>
                <div className={styles.chooseCards}>
                    <div>
                        <div>
                            <img src={Robotik} alt={Robotik} />
                        </div>
                        <p>Vergleichen und wählen Sie den FLEXX-i Plan, der zu Ihren Bedürfnissen passt</p>
                        <p>Ob Sie einfachen Zugang zum Herunterladen Ihres Formulars oder erweiterte Tools wie vorgespeicherte
                            Daten und mehrfache Formularerstellung benötigen – unsere gestuften Pläne bieten Ihnen die nötige
                            Flexibilität. Vergleichen Sie unsere kostenlosen und Premium-Optionen, um die beste Lösung für Sie zu
                            finden und den Prozess der Formularerstellung zu optimieren.
                        </p>
                    </div>
                    <div className={styles.cards}>
                        <div className={styles.column}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>FLEXX-i Standard</p>
                                </div>
                                <div className={styles.yearly}>Frei</div>
                                <span className={styles.included}>Was enthalten ist</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Zugang zu FLEXX-i Der eifach Nette</p>
                                </span>
                                <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Antrag Abrechnung Verhinderungspflege PDF herunterladen</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Maximale Nutzung unbegrenzt</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Persönlicher Zugang zum Dashboard</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Unbegrenzte Formularerstellung</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Vorgespeicherte Daten</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Mehrere Konten für Pflegeempfänger</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Bearbeitbare Formulare</p>
                                </span>
                                    <span>
                                   <img src={CrossIcon} alt={CrossIcon} />
                                    <p>Frühzeitiger Zugang zur FLEXXI Sorgen Los Karte für 1 EUR</p>
                                </span>
                                    {/*avelacnel mnacac@*/}
                                </div>
                            </div>
                            <div className={styles.cardsBtn}>
                                <Link to={'/summary'} state={ { from: '/chooseData' } }>
                                    <button onClick={() => handleChooseCard('basic')}>Mit diesem Plan fortfahren</button>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.column}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>Smart</p>
                                </div>
                                <div className={styles.yearly}>
                                   <div>
                                       <span>1.5 EUR&nbsp;</span>
                                       <span>/Monat</span>
                                   </div>
                                    <div>18 EUR / Rechnungstellung für 365 Tage im Voraus</div>
                                </div>
                                <span className={styles.included}>Was enthalten ist</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Zugang zu FLEXX-i Der eifach Nette</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Antrag Abrechnung Verhinderungspflege PDF herunterladen</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Maximale Nutzung unbegrenzt</p>
                                </span>
                                    <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Persönlicher Zugang zum Dashboard</p>
                                </span>
                                 <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Unbegrenzte Formularerstellung</p>
                                </span>
                                 <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Vorgespeicherte Daten für eine schnellere Formularerstellung</p>
                                </span>
                                <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Mehrere Konten für Pflegeempfänger</p>
                                </span>
                                 <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Bearbeitbare Formulare</p>
                                </span>
                                <span>
                                   <img src={LightCheck} alt={LightCheck} />
                                    <p>Frühzeitiger Zugang zur FLEXXI Sorgen Los Karte</p>
                                </span>
                                </div>
                            </div>
                            <div className={styles.cardsBtn}>
                                <Link to={'/payment'} state={ { from: '/chooseData' } }>
                                    <button onClick={() => handleChooseCard('pro')}>Ich möchte diesen Plan</button>
                                </Link>
                            </div>
                        </div>
                        <div className={cx(styles.column)}>
                            <div>
                                <div>
                                    <img src={MiniLogo} alt={MiniLogo} />
                                    <p>Premium</p>
                                </div>
                                <div className={styles.yearly}>€41&nbsp;<span>/yearly</span></div>
                                <span className={styles.included}>What’s included</span>
                                <div className={styles.CheckLists}>
                                <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>All analytics features</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 5,000,000 tracked visits</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Dedicated support</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 50 team members</p>
                                </span>
                                    <span>
                                   <img src={PurplleCheck} alt={PurplleCheck} />
                                    <p>Up to 50 team members</p>
                                </span>
                                </div>
                            </div>
                            <div className={cx(styles.cardsBtn,{[styles.disableBtn]: isProduction()})}>
                                <Link to={'/payment'} state={ { from: '/chooseData' } }>
                                    <button disabled={isProduction()} onClick={() => handleChooseCard('premium')}>Ich möchte diesen Plan</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Robotik} alt={Robotik} />
                </div>
            </div>
        </PageComponent>
    )
}
export default ChooseYourData
