import React from 'react'
import cx from 'classnames'
import styles from './modalHeader.module.scss'
import CloseIcon from '../../../../icons/closeIcon.svg'
import { useDispatch, useSelector } from 'react-redux'
import {closeConfirmationModal} from "../../../../redux/actions/confirmationModal";

export default ({
  text,
  warning,
  stageCount,
  stage,
  handleCancel,
  handleBackStage,
  hideCloseButton,
  checked,
}) => {
  const arr = new Array(stageCount).fill(stageCount)
  const {
    confirmationModal: { body },
  } = useSelector((state) => state)
  const { formEdit} = useSelector((state) => state.quizQuestions)
  const dispatch = useDispatch()


  return (
    <header
      className={cx(styles.headerStyles, {
        [styles.stages]: stageCount,
        [styles.hideCloseButton]: hideCloseButton,
        // [styles.fontInGerman]: selectedLanguage !== 'en',
      })}
    >
      {checked && (
        <img
          className={styles.checkboxStyles}
          alt={'Select'}
        />
      )}
      {warning ? (
        <div className={styles.reportHeader}>
          {/*<img src={ErrorIcon} alt={ErrorIcon} />*/}
          <span>{text}</span>
        </div>
      ) : stageCount ? (
        <div className={styles.stages}>
          {arr.map((item, index) => (
            <div
              className={cx(styles.stage, {
                [styles.disabledStage]: stage <= index,
              })}
              key={index}
            />
          ))}
        </div>
      ) : (
        <span>{text}</span>
      )}
      {!hideCloseButton && !formEdit &&  (
        <img src={CloseIcon} onClick={() => dispatch(closeConfirmationModal())} alt={'Close'} />
      )}
    </header>
  )
}
