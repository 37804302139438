import React from "react";
import styles from './contactPerson.module.scss'
import Avatar from '../../../../icons/avatar.svg';

const ContactPerson = () => {
   return (
       <div className={styles.contactPerson}>
           <p>Sie können uns auch kontaktieren</p>
           <div>
               <div>
                   <img src={Avatar} alt={Avatar}/>
               </div>
               <div>
                   <p>Lejla Hadzimuratovic</p>
                   <p>Kunden Betreuung</p>
                   <p>support@flexxi.care</p>
                   <p>+49 172 4620874</p>
               </div>
           </div>
       </div>
   )
}
export default ContactPerson
